import React, { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'

const Forgotpass = () => {


    const selectStyle = {
        width: "100%",
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        marginBottom: "20px",
        marginTop: "5px",
        color: "#333",
        fontSize: "16px",
        backgroundColor: "#f9f9f9",
        outline: "none",
    }



    const handleSubmit = (e) => {

        e.preventDefault();
        const email = e.target.email.value;
        const role = e.target.role.value;

        const data = {
            "email": email,
            "role": role
        }

        axios.post('http://localhost:8081/common/checkemail', data).then((res) => {

            if (res.message === 'Valid') {
                Swal.fire({
                    title: "User Not Found !",
                    icon: "error",
                });

            } else {


                Swal.fire({
                    title: "Password Reset Link Sent to Your Email",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                }).then(() => {

                    axios.post('http://localhost:8081/common/sendpassword', { id: res.data[0].id, email: res.data[0].email }).then((res) => {
                        window.location.href = '/';
                    }).catch((err) => { console.log(err) })


                });
            }


        }).catch((err) => { console.log(err) })


    }



    return (
        <div> <div className="login-container">
            <div className="login-box">
                <div className="logo-section">
                    {/* <a href="/"><img src={Img} alt="logo.png" width={50} /></a> */}
                </div>
                <h3>FORGOT PASSWORD</h3>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />
                    </div>


                    <div className="input-group password-containe">
                        <label htmlFor="role">Role</label>
                        <select name="role" id="role" style={selectStyle} required>
                            <option value="null">Select Role</option>
                            <option value="ins">Institute</option>
                            <option value="gc">Government Officer</option>
                        </select>
                    </div>

                    <button type="submit" className="login-button" style={{ width: "100%" }}>SEND</button>
                </form>



            </div>
        </div>
        </div>
    )
}

export default Forgotpass