import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha'; 
import '../assets/css/courseView.css';
import '../assets/css/applyform.css';

const ApplyForm = () => {


  const courses_id = sessionStorage.getItem('id');
  const institute_id = sessionStorage.getItem('id');


  const recaptchaRef = useRef(null);
  
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    dob: '', 
    address: '',
    district: '',
    province: '', 
    email: '',
    levelOfStudy: '',
    message: '',
  });
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleRecaptcha = (value) => {
    setCaptchaValue(value);
    console.log("CAPTCHA value:", value); 
  };

  // useEffect(() => {
  //   const fetchInstituteDetails = async () => {
  //     try {
  //       const response = await axios.get(`http://localhost:8081/ins/one/${institute_id}`);
  //       const institute = response.data[0];
  //       setFormData(prevData => ({
  //         ...prevData,
          
  //       }));
  //     } catch (error) {
  //       console.error('Error fetching institute details:', error);
  //     }
  //   };
  //   fetchInstituteDetails();
  // }, [institute_id]);


  // useEffect(() => {
  //   const fetchCourseDetails = async () => {
  //     try {
  //       const response = await axios.get(`http://localhost:8081/course/one/${courses_id}`);
  //       const course = response.data[0];
  //       setFormData(prevData => ({
  //         ...prevData,
          
  //       }));
  //     } catch (error) {
  //       console.error('Error fetching institute details:', error);
  //     }
  //   };
  //   fetchCourseDetails();
  // }, [courses_id]);

  

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaValue) {
        alert('Please complete the CAPTCHA');
        return;
    }

    try {
        const response = await axios.post('http://localhost:8081/apply/apply', {
            ...formData,
            institute_id: sessionStorage.getItem('institute_id'),
            courses_id: sessionStorage.getItem('courses_id'),
        });
        alert(response.data.message);

        // Clear the form fields after successful submission
        setFormData({
            name: '',
            mobile: '',
            dob: '', 
            address: '',
            district: '',
            province: '', 
            email: '',
            levelOfStudy: '',
            message: '',
        });
        recaptchaRef.current.reset(); // Reset the CAPTCHA
    } catch (error) {
        console.error('Error submitting form:', error);
        if (error.response && error.response.data) {
            alert(error.response.data.message || 'Failed to submit the form.');
        } else {
            alert('Failed to submit the form. Please try again later.');
        }
    }
};

  return (
    <div className="apply-form">
      <h3>APPLY NOW</h3>
      <hr id="hr-programme" />
      <form onSubmit={handleSubmit}>
        <div className="mainform">
          <div className="form-row">
            <div className="form-group half-width">
              <label>Full Name *</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
    
          </div>
          <div className="form-row">
            <div className="form-group half-width">
              <label>Contact Number *</label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group half-width"> 
              <label>Date of Birth *</label>
              <input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-row">
           <div className="form-group half-width">
              <label>Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Address"
                required
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group half-width">
              <label>District *</label>
              <select
                  id="district"
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  required
                >
                  <option value="" >
                    Select District
                  </option>
                  <option value="Ampara">Ampara</option>
                  <option value="Anuradhapura">Anuradhapura</option>
                  <option value="Badulla">Badulla</option>
                  <option value="Batticaloa">Batticaloa</option>
                  <option value="Colombo">Colombo</option>
                  <option value="Galle">Galle</option>
                  <option value="Gampaha">Gampaha</option>
                  <option value="Hambantota">Hambantota</option>
                  <option value="Jaffna">Jaffna</option>
                  <option value="Kalutara">Kalutara</option>
                  <option value="Kandy">Kandy</option>
                  <option value="Kegalle">Kegalle</option>
                  <option value="Kilinochchi">Kilinochchi</option>
                  <option value="Kurunegala">Kurunegala</option>
                  <option value="Mannar">Mannar</option>
                  <option value="Matale">Matale</option>
                  <option value="Matara">Matara</option>
                  <option value="Monaragala">Monaragala</option>
                  <option value="Mullaitivu">Mullaitivu</option>
                  <option value="NuwaraEliya">Nuwara Eliya</option>
                  <option value="Polonnaruwa">Polonnaruwa</option>
                  <option value="Puttalam">Puttalam</option>
                  <option value="Ratnapura">Ratnapura</option>
                  <option value="Trincomalee">Trincomalee</option>
                  <option value="Vavuniya">Vavuniya</option>
                </select>
            </div>
            <div className="form-group half-width"> 
              <label>Province</label>
              <select
                  id="province"
                  name="province"
                  value={formData.province}
                  onChange={handleChange}
                  required
                >
                  <option value="" >
                    Select Province
                  </option>
                  <option value="Northern">Northern</option>
                  <option value="North Western">North Western</option>
                  <option value="Western">Western</option>
                  <option value="North Central">North Central</option>
                  <option value="Central">Central</option>
                  <option value="Sabaragamuwa">Sabaragamuwa</option>
                  <option value="Eastern">Eastern</option>
                  <option value="Uva">Uva</option>
                  <option value="Southern">Southern</option>
                </select>
            </div>
          </div>
        

          <div className="form-row">
            <div className="form-group half-width">
              <label>Email Address *</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label>Level of Study</label>
            <select
              name="levelOfStudy"
              value={formData.levelOfStudy}
              onChange={handleChange}
              required
            >
              <option value="">Select Level</option>
              <option value="undergraduate">Undergraduate</option>
              <option value="graduate">Graduate</option>
              <option value="postgraduate">Postgraduate</option>
              <option value="doctorate">Doctorate</option>
            </select>
          </div>

          <div className="form-group">
            <label>Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>

          <div className="form-group">
            <ReCAPTCHA
              sitekey="6LcQuB8qAAAAAKC1f8VaLybrfzumLfQ7HMBCEOO0" 
              onChange={handleRecaptcha}
              ref={recaptchaRef}
            />
          </div>

          <button type="submit">SUBMIT</button>
        </div>
      </form>
    </div>
  );
};

export default ApplyForm;
