import React, { useEffect, useState } from 'react'
import '../assets/css/instituteview.css'
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';
import axios from 'axios';
import { useParams } from 'react-router';

const InsView = () => {

    const { id } = useParams();

    const BaseUrl = 'http://localhost:8081/ins/'

    const [institute, setInstitute] = useState({
        id: '',
        name: '',
        email: '',
        website: '',
        phone: '',
        address: '',
        location: '',
        logo: '',
        description: '',
        token: ''
    })



    const getOneData = async () => {
        axios.get(`${BaseUrl}/one/${id}`).then((response) => {
            setInstitute({
                id: response.data[0].id,
                name: response.data[0].in_name,
                email: response.data[0].email,
                website: response.data[0].website,
                phone: response.data[0].tel,
                address: response.data[0].address,
                location: response.data.location,
                logo: response.data[0].img,
                description: response.data[0].desc_,
                token: response.data[0].token
            })
            console.log(response.data)
        }).catch((err) => {
            console.log(err)
        })
    }


    const [course, setCourse] = useState([])

    const [showAll, setShowAll] = useState(false);

    const displayedCourses = showAll ? course : course.slice(0, 4);

    const getCourseData = async () => {

        axios.get(`${BaseUrl}/course/` + id).then((response) => {
            setCourse(response.data)
        })
    }

    useEffect(() => {

        getOneData();
        getCourseData();

    }, [id])




    return (

        <div className='institutesView'>
            <SecondNav />
            {/* <div className="homelink">
                <ul className='nav-link'>
                    <li><img src={home} alt="" /></li>
                    <li id='nav-link-img'> <img src={event} alt="" /></li>
                </ul>
            </div> */}

            <div className="inview">

                <div className="block-one-inview">

                    <div className="heading-text-inview">
                        <h3>{institute.name}</h3>
                        <hr id="hr-inview" />
                    </div>

                    <div className="img-inview">
                        <img src={`http://localhost:8081/Upload/Images/` + institute.logo} alt="Institute" width={300} />
                    </div>

                    <div className="content-inview">
                        <p style={{lineHeight:"30px"}}>{institute.description}</p>
                    </div>

                    <div className="map-section">
                        <iframe src={institute.token} allowfullscreen=""></iframe>
                    </div>

                </div>

                <div className="block-two-inview">
                    <ul>
                        <li><strong><i class="fa-solid fa-globe"></i> Website </strong> <a href={institute.website}>
                            {institute.website}</a>
                        </li>
                        <li><strong><i class="fa-solid fa-envelope"></i> Email </strong> <p>{institute.email}</p></li>
                        <li><strong><i class="fa-solid fa-phone"></i>Contact   </strong> <p>{institute.phone}</p></li>
                        <li><strong><i class="fa-solid fa-address-book"></i>Address </strong> <address> {institute.address}</address></li>
                    </ul>
                </div>



            </div>


            <div className="course-list">

                <div className="heading-text-inview course-block">
                    <h3>COURSES @ {institute.name}</h3>
                    <hr id="hr-inview" />
                </div>

                <div className="course-list-block">
                    <ul>

                        {
                            displayedCourses.map((course) => {
                                return (
                                    <li>
                                        <div className="course-box">
                                            <h3>{course.courseName}</h3>
                                            <p><strong>Duration : </strong> {course.duration}</p>
                                            <p><strong>By </strong> {institute.name}</p>
                                        </div>
                                    </li>
                                )
                            })
                        }

                    </ul>

                    <div className="view-all-button">
                        <button onClick={() => setShowAll(true)}>SEE ALL</button>
                    </div>

                </div>
            </div>

            <Footer />


        </div>

    )
}

export default InsView