import React, { useState } from 'react'
import '../assets/css/secondnav.css'
import Img from '../assets/images/skillslogo.png'
import icon1 from '../assets/images/fb.png'
import icon2 from '../assets/images/em.png'
import icon3 from '../assets/images/yt.png'
import icon4 from '../assets/images/wht.png'
import icon5 from '../assets/images/lin.png'

const SecondNav = () => {


    const [isOpen, setIsOpen] = useState("third-nav-second");

    const openMenulist = (e) => {
        e.preventDefault();

        console.log(window.location.pathname);
        isOpen === "third-nav-second" ? setIsOpen("third-nav-second nav_active") : setIsOpen("third-nav-second");
    }




    return (


        <div className='NavigationBar'>

            {/* first nav bar */}
            <nav id='nav-first-section'>

                {/* logo section */}
                <div className="logo">
                    {/* <img src={Img} alt="skillslogo.png" /> */}
                    {/* <h1>eSupport</h1> */}
                </div>

                {/* language-select  desk-view*/}
                <div className="lan-select">

                    <button><a href="!#">English</a></button>
                    <button><a href="!#">සිංහල</a></button>
                    <button><a href="!#">தமிழ்</a></button>
                </div>

                {/* mobile-view */}
                <div className="menu-icon-mob-view">

                    <select>
                        <option value="0"><a href="!">English <i class="fa-solid fa-caret-down" style={{ color: "#fff" }}></i></a></option>
                        <option value="1"><a href="!">සිංහල</a></option>
                        <option value="2"><a href="!">தமிழ் </a></option>
                    </select>

                </div>

            </nav>

            {/* second navbar */}
            <nav className="second-nav-bar">

                <div className="icon-box-nav">

                    <a href="!#"><img src={icon1} alt="" width={25} /></a>
                    <a href="!#"><img src={icon4} alt="" width={25} /></a>
                    <a href="!#"><img src={icon5} alt="" width={25} /></a>
                    <a href="!#"><img src={icon2} alt="" width={25} /></a>
                    <a href="!#"><img src={icon3} alt="" width={25} /></a>
                </div>


                {/* menu icon */}
                <a id='menu-button-second' className='reg-btn-menu-second' href='!' onClick={openMenulist} >
                    <i class="fa-solid fa-bars"></i>
                </a>


            </nav>

            <nav className={isOpen}>

                <div className="menu-list-nav2">

                    <ul className=' menu-list-nav2-ul'>

                        <li><a href="/">HOME</a></li>

                        <li id="drop4">
                            <a >COURSES  <i class="fa-solid fa-caret-down" style={{ color: "#fff" }}></i></a>
                            <div className="dropdown-content4">
                                <a href="/govcourses">Government Courses</a>
                                <a href="/govcourses">Private Courses</a>
                                {/* <a href="#">Other</a> */}
                            </div>
                        </li>

                        <li id='drop3'>
                            <a >INSTITUTES  <i class="fa-solid fa-caret-down" style={{ color: "#fff" }}></i></a>
                            <div className="dropdown-content3">
                                <a href="/institutes">Private University</a>
                                <a href="/insfore">Foreign University</a>
                                {/* <a href="!#">Other</a> */}
                            </div>
                        </li>

                        <li><a href="/studyabroad">STUDY ABROAD</a></li>

                        <li><a href="/govcourses">GOVERNMENT COURSES</a></li>

                        <li><a href="">PROFESSIONAL  EXAMS</a></li>

                        <li id='drop2'>
                            <a >REGISTER  <i class="fa-solid fa-caret-down" style={{ color: "#fff" }}></i></a>
                            <div className="dropdown-content2">
                                <a href="/stdregister">Student</a>
                                <a href="/insregister">Institute</a>
                            </div>
                        </li>

                        <li><a href="/externaldegree">EXTERNAL DEGREE</a></li>

                        <li><a href="/localjob">LOCAL JOBS</a></li>

                        <li><a href="/stdloans">STUDENT LOANS </a></li>
                
                        <li><a href="/foreign">FOREIGN JOBS</a></li>

                        <li><a href="/events">EVENTS & NEWS</a></li>

                        <li><a href="/gcview">GUIDANCE OFFICER</a></li>

                        <li><a href="/contact">CONTACT US</a></li>

                        <li><a href="/aboutus">ABOUT US</a></li>

                    </ul>

                </div>

            </nav>

        </div>
    )
}

export default SecondNav