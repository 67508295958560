import React, { useEffect, useState } from 'react'
import '../assets/css/institutes.css'
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';
import axios from 'axios';

const GovUni = () => {

    const BaseUrl = 'http://localhost:8081/ins/'

    //search value
    const [search, setSearch] = useState(' ') // [state, setState

    // Function to handle form submit
    const hadleSubmit = (e) => {
        e.preventDefault()
        setSearch(search)
    }


    const [institutes, setInstitutes] = useState([])

    useEffect(() => {
        axios.get(`${BaseUrl}govermentsl`).then((response) => {
            setInstitutes(response.data)
            console.log(response.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [institutes])


    // Filter institutes based on the search query
    const filteredInstitutes = institutes.filter(institute =>
        institute.in_name.toLowerCase().includes(search)
    );


    return (
        <>
            <SecondNav />

            <div className='institutes'>
                <div className="leftBar">

                    <div className="containerBoxOne">
                        <div className="content-warp">

                            <h4>FIND YOUR INSTITUTE</h4>
                            <p>Ranked as one of the world's most</p>

                            <form id='formInstitutes' onSubmit={hadleSubmit} >
                                <input type="text" placeholder="Enter Institute" onChange={(e) => {
                                    setSearch(e.target.value.toLowerCase());
                                }} />
                                <input type="submit" value="Search" />
                            </form>
                        </div>
                    </div>
                </div>

                <div className="institutesContainer">

                    <div className="containerBoxTwo">

                        {
                            search === ' ' ? (
                                institutes.map(institute => (
                                    <a key={institute.id} href={`/institutesview/${institute.id}`}>
                                        <div className="ins-box">
                                            <div className="ins-box-img">
                                                <img
                                                    src={`http://localhost:8081/Upload/Images/${institute.img}`}
                                                    alt="Institute"
                                                />
                                            </div>
                                            <div className="ins-box-content">
                                                <p>{institute.in_name}</p>
                                            </div>
                                        </div>
                                    </a>
                                ))) : (


                                filteredInstitutes.length > 0 ? (
                                    filteredInstitutes.map(institute => (
                                        <a key={institute.id} href={`/institutesview/${institute.id}`}>
                                            <div className="ins-box">
                                                <div className="ins-box-img">
                                                    <img
                                                        src={`http://localhost:8081/Upload/Images/${institute.img}`}
                                                        alt="Institute"
                                                    />
                                                </div>
                                                <div className="ins-box-content">
                                                    <p>{institute.in_name}</p>
                                                </div>
                                            </div>
                                        </a>
                                    ))
                                ) : (
                                    <p style={{ color: "black" }}>No institutes found</p>
                                )


                            )


                        }




                    </div>
                </div>

            </div>
            <Footer />
        </>

    )
}

export default GovUni