import React, { useEffect, useState } from 'react';
import InstituteDashboard from './InstituteDashboard';
import '../../assets/css/Institute/CreateCourse.css';
import axios from 'axios';

const CreateCourse = () => {
  const institute_id = sessionStorage.getItem('id');

  const [formData, setFormData] = useState({
    university: '',
    universityType: '',
    courseName: '',
    level: '',
    duration: '',
    startDate: '',
    category: '',
    specification: '',
    courseFee: '',
    careerOpportunities: '',
    industry: '',
    cologo: null,
    codescription: '',
    instituteAddress: '',
    institutePhone: '',
    instituteEmail: '',
    instituteLocation: ''
  });

  useEffect(() => {
    const fetchInstituteDetails = async () => {
      try {
        const response = await axios.get(`http://localhost:8081/ins/one/${institute_id}`);
        const institute = response.data[0];
        setFormData(prevData => ({
          ...prevData,
          university: institute.in_name || '',
          instituteAddress: institute.address || '',
          institutePhone: institute.tel || '',
          instituteEmail: institute.email || '',
          universityType:institute.type||'',
          // instituteLocation: institute.token || ''
        }));
      } catch (error) {
        console.error('Error fetching institute details:', error);
      }
    };
    fetchInstituteDetails();
  }, [institute_id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      setFormData(prevData => ({
        ...prevData,
        [name]: files[0]
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
  
    for (const key in formData) {
      if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    }
  
    formDataToSend.append('institute_id', institute_id);
  
    try {
      const response = await fetch('http://localhost:8081/course/create', {
        method: 'POST',
        body: formDataToSend
      });
  
      const contentType = response.headers.get('Content-Type');
      let data;
  
      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else {
        data = await response.text();
      }
  
      if (response.ok) {
        console.log('Form submitted:', data);
        window.alert('Course created successfully!');
      } else {
        console.error('Error response:', data);
        window.alert(data.message || 'Failed to create course. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      window.alert('Failed to create course. Please try again.');
    }
  };
  

  return (
    <main className='institute-create-course'>
      <InstituteDashboard />
      <div className="head-content-text">
        <h4>ADD Course</h4>
      </div>
      <div className="sa-container">
        <form onSubmit={handleSubmit}>
          <div className="new">
           
            <div className="formgroup">
              <i className="fas fa-building"></i>
              <input
                type="text"
                id="university"
                name="university"
                value={formData.university}
                onChange={handleChange}
                placeholder="University / Institute Name"
              />
            </div>

            <div className="formgroup">
            <i className="fas fa-university"></i>
              <input type="text" id="universityType" name="universityType" value={formData.universityType} onChange={handleChange} placeholder="University / Institute Name"/>
            </div>

            {/* <div className="formgroup">
              <i className="fas fa-university"></i>
              <select id="universityType" name="universityType" value={formData.universityType} onChange={handleChange}>
                <option value="" disabled>Select University / Institute Type</option>
                <option value="Government">Government</option>
                <option value="Private">Private</option>
              </select>
            </div> */}

            <div className="formgroup">
              <i className="fas fa-book"></i>
              <input type="text" id="courseName" name="courseName" value={formData.courseName} onChange={handleChange} placeholder="Course/ Degree Name" />
            </div>

            <div className="formgroup">
              <i className="fas fa-book-reader"></i>
              <select id="category" name="category" value={formData.category} onChange={handleChange} placeholder="Category">
                <option value="" disabled>Select Course Category</option>
                <option value="Business">Business</option>
                <option value="Engineering">Engineering</option>
                <option value="Science">Science</option>
                <option value="Arts">Arts</option>
                <option value="Technology">IT</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-layer-group"></i>
              <select id="level" name="level" value={formData.level} onChange={handleChange}>
                <option value="" disabled>Select Course Level</option>
                <option value="Foundation">Foundation</option>
                <option value="Training">Training</option>
                <option value="Certificate">Certificate</option>
                <option value="Diploma">Diploma</option>
                <option value="Advanced Diploma">Advanced Diploma</option>
                <option value="HND">HND</option>
                <option value="Degree">Degree</option>
                <option value="Postgraduate Diploma">Postgraduate Diploma</option>
                <option value="Master's">Master's</option>
                <option value="PhD">PhD</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-hourglass-start"></i>
              <input type="text" id="duration" name="duration" value={formData.duration} onChange={handleChange} placeholder="Duration" />
            </div>

            <div className="formgroup">
              <i className="fas fa-calendar-alt"></i>
              <input type="date" id="startDate" name="startDate" value={formData.startDate} onChange={handleChange} placeholder="Start Date" />
            </div>

            <div className="formgroup">
              <i className="fas fa-cogs"></i>
              <input type="text" id="specification" name="specification" value={formData.specification} onChange={handleChange} placeholder="Specification" />
            </div>

            <div className="formgroup">
              <i className="fa fa-money-bill-alt"></i>
              <input type="text" id="courseFee" name="courseFee" value={formData.courseFee} onChange={handleChange} placeholder="Course Fee" />
            </div>

            <div className="formgroup">
              <i className="fas fa-briefcase"></i>
              <input type="text" id="careerOpportunities" name="careerOpportunities" value={formData.careerOpportunities} onChange={handleChange} placeholder="Career Opportunities" />
            </div>

            <div className="formgroup">
              <i className="fas fa-industry"></i>
              <input type="text" id="industry" name="industry" value={formData.industry} onChange={handleChange} placeholder="Industry" />
            </div>

            <div className="formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Drop Course Banner</span>
                <input type="file" accept="image/*" id="file-input" name='cologo' onChange={handleChange} />
              </label>
            </div>

            <div className="formgroup textarea">
              <textarea name="codescription" placeholder='Enter the description of the Course background........ ' onChange={handleChange} value={formData.codescription}></textarea>
            </div>

            {/* Contact Details */}
            <div className="formgroup">
              <h4>Contact Details</h4>
            </div>

            <div className="formgroup">
            <i class="fas fa-address-card"></i>
              <input
                type="text"
                id="instituteAddress"
                name="instituteAddress"
                value={formData.instituteAddress}
                onChange={handleChange}
                placeholder="Institute Address"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-phone"></i>
              <input
                type="tel"
                id="institutePhone"
                name="institutePhone"
                value={formData.institutePhone}
                onChange={handleChange}
                placeholder="Institute Phone"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                id="instituteEmail"
                name="instituteEmail"
                value={formData.instituteEmail}
                onChange={handleChange}
                placeholder="Institute Email"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input type="text" id="instituteLocation" name="instituteLocation" value={formData.instituteLocation} onChange={handleChange} placeholder="Institute Location" />
            </div>

            <button type="submit">CREATE</button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default CreateCourse;
