import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/courses.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';

const StudentLoans = () => {
  const [isCategoriesExpanded, setCategoriesExpanded] = useState(false);
  const [isLevelsExpanded, setLevelsExpanded] = useState(false);
  const [isInstitutionExpanded, setInstitutionExpanded] = useState(false);
  const [stdLoans, setStdLoans] = useState([]);
  const [filters, setFilters] = useState({
    universityType: '',
    category: '',
    level: ''
  });
  const [displayedLoansCount, setDisplayedLoansCount] = useState(4);

  const navigate = useNavigate();

  const toggleCategories = () => setCategoriesExpanded(!isCategoriesExpanded);
  const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);
  const toggleInstitution = () => setInstitutionExpanded(!isInstitutionExpanded);

  const fetchStdLoans = async () => {
    try {
      const query = new URLSearchParams(filters).toString();
      const response = await fetch(`http://localhost:8081/stdLoans/all?${query}`);
      if (response.ok) {
        const data = await response.json();
        setStdLoans(data);
      } else {
        console.error('Error fetching student loans:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching student loans:', error);
    }
  };

  useEffect(() => {
    fetchStdLoans();
  }, [filters]);

  const handleLoanClick = (id) => {
    navigate(`/stdloanview/${id}`);
  };

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value
    }));
  };

  const handleSeeAllLoans = () => {
    setDisplayedLoansCount((prevCount) => prevCount + 4);
  };

  const stdLoansToShow = stdLoans.slice(0, displayedLoansCount);

  return (
    <div>
      <SecondNav />
      <div className="Container">
        <div className="left-column">
          <div className="search-section">
            <h2>FIND YOUR LOAN</h2>
            <div className="Search-container">
              <input type="text" placeholder="What are you looking for?" />
              <button type="submit"><i className="fa fa-search"></i></button>
            </div>
          </div>
          {/* <div className={`Institution-section ${isInstitutionExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleInstitution}>
              Institution Type <span className="expand-arrow">{isInstitutionExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list ${isInstitutionExpanded ? 'active' : ''}`}>
              <li><input type="button" className="button-government" value="Government" onClick={() => handleFilterChange('universityType', 'Government')} /></li>
              <li><input type="button" className="button-private" value="Private" onClick={() => handleFilterChange('universityType', 'Private')} /></li>
            </ul>
            <hr />
          </div> */}
          {/* <div className={`Categories-section ${isCategoriesExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleCategories}>
              Degree Name <span className="expand-arrow">{isCategoriesExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list ${isCategoriesExpanded ? 'active' : ''}`}>
              <li><input type="button" className="button-business" value="COMPUTER SCIENCE 101" onClick={() => handleFilterChange('category', 'COMPUTER SCIENCE 101')} /></li>
              <li><input type="button" className="button-science" value="MASTER OF SCIENCE IN ENVIRONMENTAL SCIENCE" onClick={() => handleFilterChange('category', 'MASTER OF SCIENCE IN ENVIRONMENTAL SCIENCE')} /></li>
              <li><input type="button" className="button-engineering" value="Course in Computerized Accounting for SME (SME)" onClick={() => handleFilterChange('category', 'Course in Computerized Accounting for SME (SME)')} /></li>
              <li><input type="button" className="button-arts" value="DIPLOMA IN ACCOUNTING & FINANCE" onClick={() => handleFilterChange('category', 'DIPLOMA IN ACCOUNTING & FINANCE')} /></li>
              <li><input type="button" className="button-IT" value="CS" onClick={() => handleFilterChange('category', 'CS')} /></li>
              <li><input type="button" className="button-training" value="BSc Honours in Medical Imaging Technology" onClick={() => handleFilterChange('category', 'BSc Honours in Medical Imaging Technology')} /></li>
              <li><input type="button" className="button-degree" value="SE" onClick={() => handleFilterChange('category', 'SE')} /></li>
              <li><input type="button" className="button-masters" value="M.Sc. in Construction Project Management." onClick={() => handleFilterChange('category', 'M.Sc. in Construction Project Management.')} /></li>
              <li><input type="button" className="button-hnd" value="Foundation in Technology and Engineering (FITE)" onClick={() => handleFilterChange('category', 'Foundation in Technology and Engineering (FITE)')} /></li>
            </ul>
            <hr />
          </div> */}

<div className={`Categories-section ${isCategoriesExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleCategories}>
              Category <span className="expand-arrow">{isCategoriesExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list ${isCategoriesExpanded ? 'active' : ''}`}>
              <li><input type="button" className="button-business" value="Business" onClick={() => handleFilterChange('category', 'Business')} /></li>
              <li><input type="button" className="button-science" value="Science" onClick={() => handleFilterChange('category', 'Science')} /></li>
              <li><input type="button" className="button-engineering" value="Engineering" onClick={() => handleFilterChange('category', 'Engineering')} /></li>
              <li><input type="button" className="button-arts" value="Arts" onClick={() => handleFilterChange('category', 'Arts')} /></li>
              <li><input type="button" className="button-IT" value="IT" onClick={() => handleFilterChange('category', 'IT')} /></li>
            </ul>
            <hr />
          </div>
          <div className={`Levels-section ${isLevelsExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleLevels}>
              Levels <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
              <li><input type="button" className="button-foundation" value="Foundation" onClick={() => handleFilterChange('level', 'Foundation')} /></li>
              <li><input type="button" className="button-training" value="Training" onClick={() => handleFilterChange('level', 'Training')} /></li>
              <li><input type="button" className="button-certificate" value="Certificate" onClick={() => handleFilterChange('level', 'Certificate')} /></li>
              <li><input type="button" className="button-diploma" value="Diploma" onClick={() => handleFilterChange('level', 'Diploma')} /></li>
              <li><input type="button" className="button-advanced-diploma" value="Advanced Diploma" onClick={() => handleFilterChange('level', 'Advanced Diploma')} /></li>
              <li><input type="button" className="button-hnd" value="HND" onClick={() => handleFilterChange('level', 'HND')} /></li>
              <li><input type="button" className="button-degree" value="Degree" onClick={() => handleFilterChange('level', 'Degree')} /></li>
              <li><input type="button" className="button-bachelor" value="Bachelor" onClick={() => handleFilterChange('level', 'Bachelor')} /></li>
              <li><input type="button" className="button-postgraduate-diploma" value="Postgraduate Diploma" onClick={() => handleFilterChange('level', 'Postgraduate Diploma')} /></li>
              <li><input type="button" className="button-masters" value="Masters" onClick={() => handleFilterChange('level', 'Masters')} /></li>
              <li><input type="button" className="button-phd" value="PhD" onClick={() => handleFilterChange('level', 'PhD')} /></li>
            </ul>
          </div>
          <div className={`Levels-section ${isLevelsExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleLevels}>
              University /Institute <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
              <li><input type="button" className="button-foundation" value="AAT Sri Lanka" onClick={() => handleFilterChange('university', 'AAT Sri Lanka')} /></li>
              <li><input type="button" className="button-training" value="SLIIT" onClick={() => handleFilterChange('level', 'SLIIT')} /></li>
              <li><input type="button" className="button-certificate" value="University of Colombo" onClick={() => handleFilterChange('level', 'University of Colombo')} /></li>
              <li><input type="button" className="button-diploma" value="University of Moratuwa" onClick={() => handleFilterChange('level', 'University of Moratuwa')} /></li>
              <li><input type="button" className="button-advanced-diploma" value="Western Sydney University" onClick={() => handleFilterChange('level', 'Western Sydney University')} /></li>
              <li><input type="button" className="button-hnd" value="Curtin University" onClick={() => handleFilterChange('level', 'Curtin University')} /></li>
              <li><input type="button" className="button-degree" value="IIT" onClick={() => handleFilterChange('level', 'IIT')} /></li>
              <li><input type="button" className="button-bachelor" value="CSCT Campus" onClick={() => handleFilterChange('level', 'CSCT Campus')} /></li>
              <li><input type="button" className="button-postgraduate-diploma" value="AIC" onClick={() => handleFilterChange('level', 'AIC')} /></li>
              <li><input type="button" className="button-masters" value="ESOFT" onClick={() => handleFilterChange('level', 'ESOFT')} /></li>
              <li><input type="button" className="button-phd" value="SLIIT" onClick={() => handleFilterChange('level', 'SLIIT')} /></li>
            </ul>
          </div>
        </div>

        <div className="right-column">
          {stdLoansToShow.map(loan => (
            <div className="Course" key={loan.id} onClick={() => handleLoanClick(loan.id)}>
                 <div className="calender">
                <div className='Date-top'></div>
                <div className="Date">{new Date(loan.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</div>
              </div>
              <div className="Course-info">
                <a href={`/stdloanview/${loan.id}`}><strong>{loan.degreeName}</strong></a>
                <p>Start Date: {new Date(loan.startDate).toLocaleDateString('en-CA')}</p>
                <p>Duration: {loan.duration}</p>
                <p>Level: {loan.level}</p>
                <p>Field: {loan.category}</p>
                <hr />
                <p1>University: <a href="#"><strong>{loan.university}</strong></a></p1>
              </div>
            </div>
          ))}
          {displayedLoansCount < stdLoans.length && (
            <button className="more-loans" onClick={handleSeeAllLoans}>SEE MORE LOANS</button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default StudentLoans;
