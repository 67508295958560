import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/courses.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';

const Tuition = () => {
    const [isCategoriesExpanded, setCategoriesExpanded] = useState(false);
    const [isLevelsExpanded, setLevelsExpanded] = useState(false);
    const [isClassExpanded, setClassExpanded] = useState(false); 
    const [tuitions, setTuitions] = useState([]);
    const [filters, setFilters] = useState({
        classType: '', 
        subject: '',
        level: ''
    });
    const [displayedTuitionsCount, setDisplayedTuitionsCount] = useState(4);

    const navigate = useNavigate();

    const toggleCategories = () => setCategoriesExpanded(!isCategoriesExpanded);
    const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);
    const toggleClass = () => setClassExpanded(!isClassExpanded); 

    const fetchTuitions = async () => { 
        try {
          const query = new URLSearchParams(filters).toString();
          const response = await fetch(`http://localhost:8081/tuition/all?${query}`);
          if (response.ok) {
            const data = await response.json();
            setTuitions(data);
          } else {
            console.error('Error fetching tuitions:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching tuitions:', error);
        }
    };
    
    useEffect(() => {
        fetchTuitions();
      }, [filters]);
    
      const handleTuitionClick = (id) => {
        navigate(`/tuitionview/${id}`);
      };
    
      const handleFilterChange = (type, value) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [type]: value
        }));
      };
    
      const handleSeeAllTuitions = () => {
        setDisplayedTuitionsCount((prevCount) => prevCount + 4);
      };
    
      const tuitionsToShow = tuitions.slice(0, displayedTuitionsCount);
    

    return (
        <div> 
            <SecondNav/>
            <div className="Container">   
                <div className="left-column">
                    <div className="search-section">
                        <h2>FIND YOUR CLASS</h2>
                        <div className="Search-container">
                            <input type="text" placeholder="What do you want to learn?" />
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </div>
                    <div className={`Institution-section ${isClassExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleClass}>
                            Class Type <span className="expand-arrow">{isClassExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isClassExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-government" value="Individual" onClick={() => handleFilterChange('classType', 'Individual')} /></li>
                            <li><input type="button" className="button-private" value="Group" onClick={() => handleFilterChange('classType', 'Group')} /></li>
                            <li><input type="button" className="button-phd" value="Hall" onClick={() => handleFilterChange('classType', 'Hall')} /></li> 
                            <li><input type="button" className="button-hnd" value="All" onClick={() => handleFilterChange('classType', 'All')} /></li> 
                            <li><input type="button" className="button-postgraduate-diploma" value="Other" onClick={() => handleFilterChange('classType', 'Other')} /></li> 
                        </ul>
                        <hr />
                    </div>
                    <div className={`Categories-section ${isCategoriesExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleCategories}>
                            Subjects <span className="expand-arrow">{isCategoriesExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isCategoriesExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-business" value="English" onClick={() => handleFilterChange('subject', 'English')} /></li>
                            <li><input type="button" className="button-science" value="Science" onClick={() => handleFilterChange('subject', 'Science')} /></li>
                            <li><input type="button" className="button-engineering" value="Sinhala" onClick={() => handleFilterChange('subject', 'Sinhala')} /></li>
                            <li><input type="button" className="button-arts" value="Arts" onClick={() => handleFilterChange('subject', 'Arts')} /></li>
                            <li><input type="button" className="button-IT" value="IT" onClick={() => handleFilterChange('subject', 'IT')} /></li>
                        </ul>
                        <hr />
                    </div>
                    <div className={`Levels-section ${isLevelsExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleLevels}>
              District <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
              <li><input type="button" className="button-foundation" value="Ampara" onClick={() => handleFilterChange('district', 'Ampara')} /></li>
              <li><input type="button" className="button-training" value="Anuradhapura" onClick={() => handleFilterChange('district', 'Anuradhapura')} /></li>
              <li><input type="button" className="button-certificate" value="Badulla" onClick={() => handleFilterChange('district', ' Badulla')} /></li>
              <li><input type="button" className="button-diploma" value="Batticaloa" onClick={() => handleFilterChange('district', 'Batticaloa')} /></li>
              <li><input type="button" className="button-advanced-diploma" value="Colombo" onClick={() => handleFilterChange('district', 'Colombo')} /></li>
              <li><input type="button" className="button-hnd" value="Galle" onClick={() => handleFilterChange('district', 'Galle')} /></li>
              <li><input type="button" className="button-degree" value="Gampaha" onClick={() => handleFilterChange('district', 'Gampaha')} /></li>
              <li><input type="button" className="button-bachelor" value="Hambantota" onClick={() => handleFilterChange('district', 'Hambantota')} /></li>
              <li><input type="button" className="button-postgraduate-diploma" value="Jaffna" onClick={() => handleFilterChange('district', 'Jaffna')} /></li>
              <li><input type="button" className="button-masters" value="Kalutara" onClick={() => handleFilterChange('district', 'Kalutara')} /></li>
              <li><input type="button" className="button-phd" value="Kandy" onClick={() => handleFilterChange('district', 'Kandy')} /></li>

              <li><input type="button" className="button-foundation" value="Kegalle" onClick={() => handleFilterChange('district', 'Kegalle')} /></li>
              <li><input type="button" className="button-training" value="Kilinochchi" onClick={() => handleFilterChange('district', 'Kilinochchi')} /></li>
              <li><input type="button" className="button-certificate" value="Kurunegala" onClick={() => handleFilterChange('district', ' Kurunegala')} /></li>
              <li><input type="button" className="button-diploma" value="Mannar" onClick={() => handleFilterChange('district', 'Mannar')} /></li>
              <li><input type="button" className="button-advanced-diploma" value="Matale" onClick={() => handleFilterChange('district', 'Matale')} /></li>
              <li><input type="button" className="button-hnd" value="Matara" onClick={() => handleFilterChange('district', 'Matara')} /></li>
              <li><input type="button" className="button-degree" value="Monaragala" onClick={() => handleFilterChange('district', 'Monaragala')} /></li>
              <li><input type="button" className="button-bachelor" value="Mullaitivu" onClick={() => handleFilterChange('district', 'Mullaitivu')} /></li>
              <li><input type="button" className="button-postgraduate-diploma" value="NuwaraEliya" onClick={() => handleFilterChange('district', 'NuwaraEliya')} /></li>
              <li><input type="button" className="button-masters" value="Polonnaruwa" onClick={() => handleFilterChange('district', 'Polonnaruwa')} /></li>
              <li><input type="button" className="button-phd" value="Puttalam" onClick={() => handleFilterChange('district', 'Puttalam')} /></li>

              <li><input type="button" className="button-certificate" value="Ratnapura" onClick={() => handleFilterChange('district', ' Ratnapura')} /></li>
              <li><input type="button" className="button-diploma" value="Trincomalee" onClick={() => handleFilterChange('district', 'Trincomalee')} /></li>
              <li><input type="button" className="button-advanced-diploma" value="Vavuniya" onClick={() => handleFilterChange('district', 'Vavuniya')} /></li>
            </ul>
          </div>
                    <div className={`Levels-section ${isLevelsExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleLevels}>
                            Level <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-training" value="A/L (Local)" onClick={() => handleFilterChange('classLevel', 'A/L (Local)')} /></li>
                            <li><input type="button" className="button-certificate" value="O/L (Local)" onClick={() => handleFilterChange('classLevel', 'O/L (Local)')} /></li>
                            <li><input type="button" className="button-diploma" value="A/L (London)" onClick={() => handleFilterChange('classLevel', 'A/L (London)')} /></li>
                            <li><input type="button" className="button-advanced-diploma" value="O/L (London)" onClick={() => handleFilterChange('classLevel', 'O/L (London)')} /></li>
                            <li><input type="button" className="button-bachelor" value="Grade 5" onClick={() => handleFilterChange('classLevel', 'Grade 5')} /></li>
                            {/* <li><input type="button" className="button-degree" value="Degree" /></li>
                            <li><input type="button" className="button-bachelor" value="Bachelor" /></li>
                            <li><input type="button" className="button-postgraduate-diploma" value="Postgraduate Diploma" /></li>
                            <li><input type="button" className="button-masters" value="Masters" /></li>
                            <li><input type="button" className="button-phd" value="PhD" /></li> */}
                        </ul>
                    </div>
                </div>

                <div className="right-column">
                    {tuitionsToShow.map(tuition => (
                        <div className="Course" key={tuition.id} onClick={() => handleTuitionClick(tuition.id)}>
                        <div className="calender">
                            <div className='Date-top'></div>
                            <div className="Date">{new Date(tuition.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</div>
                        </div>
                        <div className="Course-info">
                            <a href={`/tuitionview/${tuition.id}`}><strong>{tuition.className}</strong></a>
                            <p>Start Date: {new Date(tuition.startDate).toLocaleDateString('en-CA')}</p>
                            <p>Duration: {new Date(`1970-01-01T${tuition.time}Z`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</p>
                            <p>Level: {tuition.classLevel}</p>
                            <p>Field: {tuition.subject}</p>
                            <p>District: {tuition.district}</p>
                            <hr />
                            <p1>Teacher : <a href="#"><strong>{tuition.teacherName} | </strong></a></p1>
                            <p1>Teacher Qualification : <a href="#"><strong>{tuition.teacherQualification}</strong></a></p1>
                        </div>
                        </div>
                    ))}
                    {displayedTuitionsCount < tuitions.length && (
                        <button className="more-tuitions" onClick={handleSeeAllTuitions}>SEE MORE TUITIONS</button>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Tuition;
