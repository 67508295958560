import React, { useEffect, useState } from 'react'
import AdminDashboard from './AdminDashboard'
import '../../assets/css/admin/Admin-Dashboard-View.css'
import axios from 'axios'

const AdminViewDash = () => {




  function getDate() {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    } if (mm < 10) {
      mm = '0' + mm;
    }
    return today = mm + '-' + dd + '-' + yyyy;
  }

  const dateset = getDate();


  const [uni, SetUni] = useState([]);
  const [guni, SetPuni] = useState([]);
  const [gcou, Setgcou] = useState([]);
  const [pcou, Setpcou] = useState([]);
  const [fjob, setfjob] = useState([]);
  const [ljob, setljob] = useState([]);
  const [allEvent, setAllEvent] = useState([]);
  const [pevent, setpevent] = useState([]);
  const [astudyabroad, setastudyabroad] = useState([]);
  const [pstudyabroad, setpstudyabroad] = useState([]);
  const [exta, setexta] = useState([]);
  const [pexta, setpexta] = useState([]);
  const [school, setschool] = useState([]);
  const [pschool, setpschool] = useState([]);
  const [atute, setatute] = useState([]);
  const [ptute, setptute] = useState([])

  const getUni = () => {

    axios.get('http://localhost:8081/adminview/getpuni').then((res) => {
      SetUni(res.data);
      console.log(res.data)
    }).catch((err) => {
      console.log(err)
    })

    axios.get('http://localhost:8081/adminview/getguni').then((res) => {
      SetPuni(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/getgcou').then((res) => {
      Setgcou(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/getpcou').then((res) => {
      Setpcou(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/getfjob').then((res) => {
      setfjob(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/getgjob').then((res) => {
      setljob(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/getEvent').then((res) => {
      setAllEvent(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/getpevent').then((res) => {
      setpevent(res.data);
      console.log(res.data)
    })


    axios.get('http://localhost:8081/adminview/astudyabroad').then((res) => {
      setastudyabroad(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/pstudyabroad').then((res) => {
      setpstudyabroad(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/allexter').then((res) => {
      setexta(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/pexter').then((res) => {
      setpexta(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/getschool').then((res) => {
      setschool(res.data);
      console.log(res.data)
    })


    axios.get('http://localhost:8081/adminview/getpschool').then((res) => {
      setpschool(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/tuta').then((res) => {
      setatute(res.data);
      console.log(res.data)
    })

    axios.get('http://localhost:8081/adminview/getptute').then((res) => {
      setptute(res.data);
    })



  }

  useEffect(() => {
    getUni();
  }, [])












  return (
    <div>

      <AdminDashboard />

      <div className="AllViews-admin2-dc" >

        <div className="date-set-dc">
          <h2>{dateset}</h2>
        </div>


        <div class="parent" >

          <div class="div1">

            <div className="icon-sect-dc">
              <i class="fa-solid fa-building-columns" id='icon-dc'></i>
              <h2>Institutes</h2>
            </div>

            <div className="box-dc-1">
              <div className="icon-sect-dc-1">
                <h3>private </h3>
                <h3><span>{uni.count}</span></h3>
              </div>

              <div className="icon-sect-dc-1">
                <h3>Foreign </h3>
                <h3><span>{guni.count}</span></h3>
              </div>
            </div>

            <div className="icon-sect-dc-12">
              <button><a href="/allinstitute">SEE MORE</a></button>
            </div>

          </div>

          <div class="div2">

            <div className="icon-sect-dc">
              <i class="fa-solid fa-book" id='icon-dc'></i>

              <h2>COURSES</h2>
            </div>

            <div className="box-dc-1">
              <div className="icon-sect-dc-1">
                <h3>private </h3>
                <h3><span>{pcou.count}</span></h3>
              </div>

              <div className="icon-sect-dc-1">
                <h3>government</h3>
                <h3><span>{gcou.count}</span></h3>
              </div>
            </div>

            <div className="icon-sect-dc-12">
              <button> <a href="/allcourses">SEE MORE</a> </button>
            </div>


          </div>


          <div class="div3">
            <div className="icon-sect-dc">
              <i class="fa-solid fa-gift" id='icon-dc'></i>

              <h2>JOBS</h2>
            </div>

            <div className="box-dc-1">
              <div className="icon-sect-dc-1">
                <h3>Local Jobs</h3>
                <h3><span>{ljob.count}</span></h3>
              </div>

              <div className="icon-sect-dc-1">
                <h3>foreign Jobs</h3>
                <h3><span>{fjob.count}</span></h3>

              </div>
            </div>

            <div className="icon-sect-dc-12">
              <button> <a href="/localjoba">SEE MORE</a> </button>
            </div>
          </div>
          <div class="div4"><div className="icon-sect-dc">
            <i class="fa-solid fa-calendar-days" id='icon-dc'></i>

            <h2>EVENTS</h2>
          </div>

            <div className="box-dc-1">
              <div className="icon-sect-dc-1">
                <h3>ALL EVENETS</h3>
                <h3><span>{allEvent.count}</span></h3>
              </div>

              <div className="icon-sect-dc-1">
                <h3>PENDING EVENETS</h3>
                <h3><span>{pevent.count}</span></h3>
              </div>
            </div>

            <div className="icon-sect-dc-12">
              <button><a href="/allevents">SEE MORE</a></button>
            </div>
          </div>
          <div class="div5">
            <div className="icon-sect-dc">
              <i class="fa-solid fa-book" id='icon-dc'></i>

              <h2>Foreign Courses</h2>
            </div>

            <div className="box-dc-1">
              <div className="icon-sect-dc-1">
                <h3>All Courses</h3>
                <h3><span>{astudyabroad.count}</span></h3>
              </div>

              <div className="icon-sect-dc-1">
                <h3>Pending Courses</h3>
                <h3><span>{pstudyabroad.count}</span></h3>
              </div>
            </div>

            <div className="icon-sect-dc-12">
              <button><a href="/saallcourses">SEE MORE</a></button>
            </div>
          </div>

          <div class="div6">

            <div className="icon-sect-dc">

              <i class="fa-solid fa-book" id='icon-dc'></i>

              <h2> EXTERNAL DEGREES            </h2>
            </div>

            <div className="box-dc-1">
              <div className="icon-sect-dc-1">
                <h3>All  DEGREES</h3>
                <h3><span>{exta.count}</span></h3>
              </div>

              <div className="icon-sect-dc-1">
                <h3>Pending DEGREES</h3>
                <h3><span>{pexta.count}</span></h3>
              </div>
            </div>

            <div className="icon-sect-dc-12">
              <button><a href='/allexternaldeg'>SEE MORE</a></button>
            </div>
          </div>
          <div class="div7"><div className="icon-sect-dc">
            <i class="fa-solid fa-graduation-cap " id='icon-dc'></i>

            <h2>SCHOLARSHIPS            </h2>
          </div>

            <div className="box-dc-1">
              <div className="icon-sect-dc-1">
                <h3>All SCHOLARSHIPS</h3>
                <h3><span>{school.count}</span></h3>
              </div>

              <div className="icon-sect-dc-1">
                <h3>Pending SCHOLARSHIPS</h3>
                <h3><span>{pschool.count}</span></h3>
              </div>
            </div>

            <div className="icon-sect-dc-12">
              <button><a href="/allscholarships">SEE MORE</a></button>
            </div>
          </div>
          <div class="div8"><div className="icon-sect-dc">
            <i class="fa-solid fa-book" id='icon-dc'></i>
            <h2>Tuitions </h2>
          </div>

            <div className="box-dc-1">
              <div className="icon-sect-dc-1">
                <h3>All Tuitions </h3>
                <h3><span>{atute.count}</span></h3>
              </div>

              <div className="icon-sect-dc-1">
                <h3>Pending Tuitions                </h3>
                <h3><span>{ptute.count}</span></h3>
              </div>
            </div>

            <div className="icon-sect-dc-12">
              <button><a href="/alltuition">SEE MORE</a></button>
            </div>
          </div>
        </div>


      </div>


    </div>
  )
}

export default AdminViewDash