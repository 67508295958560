import React from 'react'
import '../assets/css/Navigationbar.css'
import Img from '../assets/images/skillslogo.png'
import icon1 from '../assets/images/fb.png'
import icon2 from '../assets/images/em.png'
import icon3 from '../assets/images/yt.png'
import icon4 from '../assets/images/wht.png'
import icon5 from '../assets/images/lin.png'

const NavigationBar = () => {

    return (

        <div className='NavigationBar'>
            
            {/* first nav bar */}
            <nav id='nav-first-section'>

                {/* logo section */}
                <div className="logo">
                    
                    {/* <h1>eSupport</h1> */}
                </div>

                {/* language-select  desk-view*/}
                <div className="lan-select">

                    <button><a href="!#">English</a></button>
                    <button><a href="!#">සිංහල</a></button>
                    <button><a href="!#">தமிழ்</a></button>
                </div>

                {/* mobile-view */}
                <div className="menu-icon-mob-view">

                    <select>
                        <option value="0"><a href="!">English <i class="fa-solid fa-caret-down" style={{ color: "#fff" }}></i></a></option>
                        <option value="1"><a href="!">සිංහල</a></option>
                        <option value="2"><a href="!">தமிழ் </a></option>
                    </select>

                </div>

            </nav>

            {/* second navbar */}
            <nav className="second-nav-bar">

                <div className="icon-box-nav">

                    <a href="!#"><img src={icon1} alt="" width={25} /></a>
                    <a href="!#"><img src={icon4} alt="" width={25} /></a>
                    <a href="!#"><img src={icon5} alt="" width={25} /></a>
                    <a href="!#"><img src={icon2} alt="" width={25} /></a>
                    <a href="!#"><img src={icon3} alt="" width={25} /></a>
                </div>

            </nav>

            <nav className="third-nav">

                <div className="menu-list-nav2">

                    <ul className=' menu-list-nav2-ul'>

                        <li><a href="/">HOME</a></li>

                        <li id="drop4">
                            <a >COURSES  <i class="fa-solid fa-caret-down" style={{ color: "#fff" }}></i></a>
                            <div className="dropdown-content4">
                                <a href="/govcourses">Government Courses</a>
                                <a href="/govcourses">Private Courses</a>
                                {/* <a href="#">Other</a> */}
                            </div>
                        </li>

                        <li id='drop3'>
                            <a >INSTITUTES  <i class="fa-solid fa-caret-down" style={{ color: "#fff" }}></i></a>

                            <div className="dropdown-content3">
                                <a href="/institutes">Private University</a>
                                <a href="/insfore">Foreign University</a>
                                {/* <a href="!#">Other</a> */}
                            </div>
                        </li>

                        <li><a href="/studyabroad">STUDY ABROAD</a></li>

                        <li><a href="/govcourses">GOVERNMENT COURSES</a></li>

                        <li><a href="/professionalexams">PROFESSIONAL  EXAMS</a></li>

                        <li id='drop2'>
                            <a >REGISTER  <i class="fa-solid fa-caret-down" style={{ color: "#fff" }}></i></a>
                            <div className="dropdown-content2">
                                <a href="/stdregister">Student</a>
                                <a href="/insregister">Institute</a>
                            </div>
                        </li>

                        <li><a href="/externaldegree">EXTERNAL DEGREE</a></li>

                        <li><a href="/localjob">LOCAL JOBS</a></li>

                        <li><a href="/stdloans">STUDENT LOANS</a></li>
                        {/* <li id='drop2'>
                            <a >STUDENT LOANS  <i class="fa-solid fa-caret-down" style={{ color: "#fff" }}></i></a>
                            <div className="dropdown-content2">
                                <a href="/createstdloan">Create Student Loan</a>
                                <a href="/stdloan">Find Student Loan</a>
                            </div>
                        </li> */}

                        <li><a href="/foreign">FOREIGN JOBS</a></li>

                        <li><a href="/events">EVENTS & NEWS</a></li>

                        <li><a href="/gcview">GUIDANCE OFFICER</a></li>

                        <li><a href="/Contact">CONTACT US</a></li>

                        <li><a href="">ABOUT US</a></li>

                    </ul>

                </div>

            </nav>

        </div>

    )

}

export default NavigationBar