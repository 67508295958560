import React from 'react'
import AdminDashboard from './AdminDashboard'
import axios from 'axios'
import Swal from 'sweetalert2'

const ADDAdvertisementManage = () => {


    const addAdvertisement1 = (e) => {

        e.preventDefault();

        const file = e.target.inlogo.files[0];

        const formData = new FormData();

        formData.append('position', 'home1')
        formData.append('img', file);
      

        axios.post('http://localhost:8081/advertisement/addImage', formData).then((res) => {
         
            Swal.fire({
                icon: 'success',
                title: 'Advertisement Added Successfully',
                showConfirmButton: false,
                timer: 1500
            })

        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Advertisement Added Failed',
                showConfirmButton: false,
                timer: 1500
            })
        });

    }

    const addAdvertisement2 = (e) => {

        e.preventDefault();

        const file = e.target.inlogo.files[0];

        const formData = new FormData();

        formData.append('position', 'home2')
        formData.append('img', file);
      

        axios.post('http://localhost:8081/advertisement/addImage', formData).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'Advertisement Added Successfully',
                showConfirmButton: false,
                timer: 1500
            })

        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Advertisement Added Failed',
                showConfirmButton: false,
                timer: 1500
            })
        });

    }

    



    return (

        <div>
            <AdminDashboard />

            <div className='AllViews-admin' >

                <div className="search-icon-admin admin-institute-search">

                    <h1>ADD ADVERTISEMENT</h1>

                </div>

                <div className="scroller-box" >

                    <form onSubmit={addAdvertisement1} >
                        <div className="form-group-1">
                            <label for="file-input" class="drop-container">
                                <span class="drop-title" style={{textTransform:"uppercase"}}>Advertisement 01 JIF</span>
                                <input type="file" accept="image/*" required="" id="file-input" name='inlogo'  />
                            </label>
                        </div>
                        <input style={{width:"100%", padding:"10px", backgroundColor:"#101418", color:"white"}} type="submit" value="ADD" />
                    </form>


                    <form onSubmit={addAdvertisement2} >
                        <div className="form-group-1">
                            <label for="file-input" class="drop-container">
                                <span class="drop-title" style={{textTransform:"uppercase"}}>Advertisement 02</span>
                                <input type="file" accept="image/*" required="" id="file-input" name='inlogo'  />
                            </label>
                        </div>
                        <input style={{width:"100%", padding:"10px", backgroundColor:"#101418", color:"white"}} type="submit" value="ADD" />
                    </form>




                </div>


            </div>
        </div>

    )
}

export default ADDAdvertisementManage