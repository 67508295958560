import React from 'react';
import '../assets/css/nav-footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import footerLogo from '../assets/images/logo.png';

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-logo">
          {/* <img src={footerLogo} alt="eSupport" /> */}
        </div>
        <div className="footer-nav">
          <a href="/">Home</a>
          <a href="/Courses">Courses</a>
          <a href="#">Advertise</a>
          <a href="/AboutUs">About Us</a>
          <a href="/Contact">Contact</a>
          <a href="#">Privacy</a>
          <a href="#">Terms</a>
        </div>
        <div className="social-links">
          <a href="#"><i className="fab fa-facebook"></i></a>
          <a href="#"><i className="fab fa-whatsapp"></i></a>
          <a href="#"><i className="fab fa-linkedin"></i></a>
          <a href="#"><i className="fas fa-envelope"></i></a>
          <a href="#"><i className="fab fa-youtube"></i></a>
          
        </div>
        
        <p className="copyright ">
                &copy; 2024 eSupport. All Rights Reserved
            </p>
      </div>
    </footer>
  );
};

export default Footer;
