import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard'
import '../../assets/css/admin/allcourses.css'

const DeleteLangQual = () => {
    const [langQualifications, setLangQualifications] = useState([]);

    useEffect(() => {
        fetchDeletedLangQualifications();
    }, []);

    const fetchDeletedLangQualifications = async () => {
        try {
            const response = await fetch('http://localhost:8081/langQualifications/deleted');
            const data = await response.json();
            setLangQualifications(data);
        } catch (error) {
            console.error('Error fetching deleted Language Qualifications:', error);
        }
    };

    const handleRestore = async (langQualificationsId) => {
        try {
            const response = await fetch(`http://localhost:8081/langQualifications/restore/${langQualificationsId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setLangQualifications(langQualifications.filter(langQualifications => langQualifications.id !== langQualificationsId));
                window.alert('Language Qualification Restored');
            } else {
                console.error('Failed to restore Language Qualification');
            }
        } catch (error) {
            console.error('Error restoring Language Qualification:', error);
        }
    };

    const handlePermanentDelete = async (langQualificationsId) => {
        try {
            const response = await fetch(`http://localhost:8081/langQualifications/delete/permanent/${langQualificationsId}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setLangQualifications(langQualifications.filter(langQualifications => langQualifications.id !== langQualificationsId));
                window.alert('Language Qualification delete permanently');
            } else {
                console.error('Failed to delete Language Qualification permanently');
            }
        } catch (error) {
            console.error('Error deleting Language Qualification permanently:', error);
        }
    };

    return (

        <div>

            <AdminDashboard />

            <div className='AllViews-admin-course'>

                <div className="search-icon-admin admin-course-search">

                    <h1>DELETE LANGUAGE QUALIFICATIONS</h1>
                    <form >
                        <input type="text" name="" id="" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                   
                </div>

                <div className="scroller-box">
                    <div className="table-course">
                    <table>
                            <thead>
                                <tr>
                                    <th>Language Exam Name</th>
                                    <th>Institute Name</th>
                                    <th>Teaching Method</th>
                                    <th>Duration</th>
                                    <th>Course Fee</th>
                                    <th>Paying Method</th>
                                    <th>Contact No.</th>
                                    <th>Email</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {langQualifications.map((langQualifications) => (
                                    <tr key={langQualifications.id}>
                                        <td>{langQualifications.langQualName}</td>
                                        <td>{langQualifications.university}</td>              
                                        <td>{langQualifications.teachingMethod}</td>
                                        <td>{langQualifications.duration}</td>
                                        <td>{langQualifications.courseFee}</td>              
                                        <td>{langQualifications.payMethod}</td>
                                        <td>{langQualifications.institutePhone}</td>
                                        <td>{langQualifications.instituteEmail}</td>
                                        <td>
                                            <a href={langQualifications.instituteWebsite} target="_blank" rel="noopener noreferrer">
                                                Visit Website
                                            </a>
                                        </td>
                                        <td id='action-buttons'>
                                            <a href="#!" id='restore-details' onClick={() => handleRestore(langQualifications.id)}>RESTORE</a>
                                            <a href="#!" id='del-details' onClick={() => handlePermanentDelete(langQualifications.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>



        </div>

    )

}

export default DeleteLangQual