import React, { useEffect, useState } from 'react'
import '../assets/css/institutes.css'
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';
import axios from 'axios';

const GCView = () => {

    const BaseUrl = 'http://localhost:8081/govofficer/'

    //search value
    const [search, setSearch] = useState(' ') // [state, setState

    // Function to handle form submit
    const hadleSubmit = (e) => {
        e.preventDefault()
        setSearch(search)
    }


    const [institutes, setInstitutes] = useState([])

    useEffect(() => {
        axios.get(`${BaseUrl}/all`).then((response) => {
            setInstitutes(response.data)
            console.log(response.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [institutes])


    // Filter institutes based on the search query
    const filteredInstitutes = institutes.filter(institute =>
        institute.district.toLowerCase().includes(search)
    );


    return (
        <>
            <SecondNav />

            <div className='institutes'>
                <div className="leftBar">

                    <div className="containerBoxOne">
                        <div className="content-warp">

                            <h4>FIND GUIDANCE OFFICER</h4>
                            <p>Find Using District</p>

                            <form id='formInstitutes' onSubmit={hadleSubmit} >
                                <input type="text" placeholder="Enter District" onChange={(e) => {
                                    setSearch(e.target.value.toLowerCase());
                                }} />
                                <input type="submit" value="Search" />
                            </form>
                        </div>
                    </div>
                </div>

                <div className="institutesContainer">

                    <div className="containerBoxTwo">

                        {
                            search === ' ' ? (
                                institutes.map(institute => (

                                    <div className="ins-box" >
                                        <div className="ins-box-img" style={{
                                            height: "200px"
                                        }}>
                                            <img
                                                src={`http://localhost:8081/Upload/Images/${institute.img}`}
                                                alt="Institute" 
                                            />
                                        </div>
                                        <div className="ins-box-content">
                                            <p><span style={{ fontWeight: 'bold', margin:'0px' }}>Name:</span> {institute.name}</p>
                                            {/* <p><span style={{ fontWeight: 'bold' }}>Email:</span> {institute.email}</p> */}
                                            <p><span style={{ fontWeight: 'bold' }}>District:</span> {institute.district}</p>
                                            <p><span style={{ fontWeight: 'bold' }}>Contact:</span> {institute.tel}</p>

                                            <button
                                                onClick={() => window.location.href = `mailto:${institute.email}`}
                                                style={{textTransform:'uppercase', padding: ' 10px', backgroundColor: '#0e0534', marginTop:'10px', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                                            >
                                                Contact via Email
                                            </button>
                                        </div>


                                    </div>

                                ))) : (


                                filteredInstitutes.length > 0 ? (
                                    filteredInstitutes.map(institute => (
                                        <div className="ins-box" >
                                        <div className="ins-box-img" style={{
                                            height: "200px"
                                        }}>
                                            <img
                                                src={`http://localhost:8081/Upload/Images/${institute.img}`}
                                                alt="Institute" 
                                            />
                                        </div>
                                        <div className="ins-box-content">
                                            <p><span style={{ fontWeight: 'bold', margin:'0px' }}>Name:</span> {institute.name}</p>
                                            {/* <p><span style={{ fontWeight: 'bold' }}>Email:</span> {institute.email}</p> */}
                                            <p><span style={{ fontWeight: 'bold' }}>District:</span> {institute.district}</p>
                                            <p><span style={{ fontWeight: 'bold' }}>Contact:</span> {institute.tel}</p>

                                            <button
                                                onClick={() => window.location.href = `mailto:${institute.email}`}
                                                style={{textTransform:'uppercase', padding: ' 10px', backgroundColor: '#0e0534', marginTop:'10px', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                                            >
                                                Contact via Email
                                            </button>
                                        </div>


                                    </div>

                                    ))
                                ) : (
                                    <p style={{ color: "black" }}>No institutes found</p>
                                )


                            )


                        }




                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default GCView