import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard'
import '../../assets/css/admin/allcourses.css'

const DeleteApplications = () => {
    const [applications, setApplications] = useState([]);

    useEffect(() => {
        fetchDeletedApplications();
    }, []);

    const fetchDeletedApplications = async () => {
        try {
            const response = await fetch('http://localhost:8081/tuition/deleted');
            const data = await response.json();
            setApplications(data);
        } catch (error) {
            console.error('Error fetching deleted applications:', error);
        }
    };

    const handleRestore = async (tuitionId) => {
        try {
            const response = await fetch(`http://localhost:8081/application/restore/${tuitionId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setApplications(applications.filter(application => application.id !== tuitionId));
                window.alert('Application Restored');
            } else {
                console.error('Failed to restore application');
            }
        } catch (error) {
            console.error('Error restoring application:', error);
        }
    };

    const handlePermanentDelete = async (tuitionId) => {
        try {
            const response = await fetch(`http://localhost:8081/application/delete/permanent/${tuitionId}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setApplications(applications.filter(application => application.id !== tuitionId));
                window.alert('Application delete permanently');
            } else {
                console.error('Failed to delete application permanently');
            }
        } catch (error) {
            console.error('Error deleting application permanently:', error);
        }
    };

    return (

        <div>

            <AdminDashboard />

            <div className='AllViews-admin-course'>

                <div className="search-icon-admin admin-course-search">

                    <h1>DELETED TUITIONS</h1>
                    <form >
                        <input type="text" name="" id="" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                   
                </div>

                <div className="scroller-box">
                    <div className="table-course">
                    <table>
                            <thead>
                                <tr>
                                    <th>Application Name</th>
                                    <th>Owner Name</th>
                                    <th>Owner Email</th>
                                    <th>Owner Contact</th>
                                    <th>Link</th>
                                    <th>PDF Application</th>
                                    <th>Expire Time</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {applications.map((application) => (
                                    <tr key={application.id}>
                                        <td>{application.appName}</td>
                                        <td>{application.ownerName}</td>
                                        <td>{application.ownerEmail}</td>
                                        <td>{application.contact}</td>
                                        <td>
                                            <a href={application.link} target="_blank" rel="noopener noreferrer">
                                                View Link
                                            </a>
                                        </td>
                                        <td>
                                            <a href={`http://localhost:8081/Upload/pdf/${application.application}`} target="_blank" rel="noopener noreferrer">
                                                View PDF
                                            </a>
                                        </td>
                                        <td>
                                            {new Date(application.expire_time).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true
                                            }).replace(',', ' -')}
                                        </td>
                                        <td><a href="#!" id='view-website'>WEBSITE</a></td>
                                        <td id='action-buttons'>
                                            <a href="#!" id='restore-details' onClick={() => handleRestore(application.id)}>RESTORE</a>
                                            <a href="#!" id='del-details' onClick={() => handlePermanentDelete(application.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>



        </div>

    )

}

export default DeleteApplications