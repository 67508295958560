import React, { useState, useEffect } from 'react';
import '../assets/css/courses.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';

const Courses = () => {
    const [isCategoriesExpanded, setCategoriesExpanded] = useState(false);
    const [isLevelsExpanded, setLevelsExpanded] = useState(false);
    const [courses, setCourses] = useState([]);

    const toggleCategories = () => setCategoriesExpanded(!isCategoriesExpanded);
    const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);

    const [displayedCoursesCount, setDisplayedCoursesCount] = useState(4);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch('http://localhost:8081/course/all');
                if (response.ok) {
                    const data = await response.json();
                    setCourses(data);
                } else {
                    console.error('Failed to fetch courses');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchCourses();
    }, []);

    const handleSeeAllCourses = () => {
        setDisplayedCoursesCount((prevCount) => prevCount + 4);
      };
    
      const coursesToShow = courses.slice(0, displayedCoursesCount);
    

    return (
        <div> 
            <SecondNav/>
            <div className="Container">   
                <div className="left-column">
                    <div className="search-section">
                        <h2>FIND YOUR COURSE</h2>
                        <div className="Search-container">
                            <input type="text" placeholder="What do you want to learn?" />
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </div>
                    <div className={`Categories-section ${isCategoriesExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleCategories}>
                            Category <span className="expand-arrow">{isCategoriesExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isCategoriesExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-business" value="Business" /></li>
                            <li><input type="button" className="button-science" value="Science" /></li>
                            <li><input type="button" className="button-engineering" value="Engineering" /></li>
                            <li><input type="button" className="button-arts" value="Arts" /></li>
                            <li><input type="button" className="button-IT" value="IT" /></li>
                        </ul>
                        <hr />
                    </div>
                    <div className={`Levels-section ${isLevelsExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleLevels}>
                            Levels <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-training" value="Training" /></li>
                            <li><input type="button" className="button-certificate" value="Certificate" /></li>
                            <li><input type="button" className="button-diploma" value="Diploma" /></li>
                            <li><input type="button" className="button-advanced-diploma" value="Advanced Diploma" /></li>
                            <li><input type="button" className="button-hnd" value="HND" /></li>
                            <li><input type="button" className="button-degree" value="Degree" /></li>
                            <li><input type="button" className="button-bachelor" value="Bachelor" /></li>
                            <li><input type="button" className="button-postgraduate-diploma" value="Postgraduate Diploma" /></li>
                            <li><input type="button" className="button-masters" value="Masters" /></li>
                            <li><input type="button" className="button-phd" value="PhD" /></li>
                        </ul>
                    </div>
                </div>

                <div className="right-column">
                    {coursesToShow.map(course => (
                        <div className="Course" key={course.id}>
                            <div className="calender">
                                <div className='Date-top'></div>
                                <div className="Date">{new Date(course.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</div>
                            </div>
                            <div className="Course-info">
                                <a href='courseview'><strong>{course.courseName}</strong></a>
                                <p>Start Date: {new Date(course.startDate).toLocaleDateString('en-CA')}</p>
                                <p>Duration: {course.duration}</p>
                                <p>Level: {course.level}</p>
                                <p>Field: {course.studyArea}</p>
                                <hr />
                                <p1>Institute: <a href="#"><strong>{course.university}</strong></a></p1>
                            </div>
                        </div>
                    ))}
                        {displayedCoursesCount < courses.length && (
                    <button className="more-courses" onClick={handleSeeAllCourses}>SEE MORE COURSES</button>
                )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Courses;
