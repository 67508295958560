import React, { useEffect, useState } from 'react'
import Img from '../../assets/images/skillslogo.png'
import '../../assets/css/admin/Admin-Dashboard.css'
import axios from 'axios';
import Swal from 'sweetalert2'


const InstituteDashboard = () => {

    if (!sessionStorage.getItem('id')) {
        window.location.href = '/rlogin'
    }

    const BaseUrl = 'http://localhost:8081/ins/'

    const [formData, setFormData] = useState({
        inname: '',
        inWebsite: '',
        inEmail: '',
        inPhone: '',
        inaddress: '',
        inlocation: '',
        inlogo: '',
        indescription: '', 
        firstlog: '',
        country: '',
        type: ''
    });

    const id = sessionStorage.getItem('id');

    const getOneData = async () => {
        axios.get(`${BaseUrl}/one/${id}`).then((response) => {
            setFormData({
                inname: response.data[0].in_name,
                inEmail: response.data[0].email,
                inWebsite: response.data[0].website,
                inPhone: response.data[0].tel,
                inaddress: response.data[0].address,
                inlocation: response.data[0].token,
                inlogo: response.data[0].img,
                indescription: response.data[0].desc_,
                firstlog: response.data[0].firstlog,
                country: response.data[0].country,
                type: response.data[0].type
            })
            console.log(response.data)
        }).catch((err) => {
            console.log(err)
        })
    }
   
    useEffect(() => {

        getOneData()
    }, [id])

    const [isOpen, setOpen] = useState('side-nav-admin');

    const hadleOpen = (e) => {
        e.preventDefault();
        isOpen === 'side-nav-admin' ? setOpen('side-nav-admin side-open') : setOpen('side-nav-admin')
    }

    const logOut = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        window.location.href = '/rlogin'
    }


    const popUp = () => {

        if (formData.firstlog === 'false') {

            Swal.fire({
                title: "Change Password",
                text: "You must change the password to your own password!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Change it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/changepass'
                }
            });
        }

    }

    popUp();



    return (


        <div id='admin-dashboard'>

            <div className="nav-bar-admin">


                {/* logo section */}
                <div className="logo-section logo-section-admin">
                    <a href="/">  <img src={Img} alt="logo.png" width={150} /></a>

                    <div className="menu" onClick={hadleOpen}>
                        <span className='menu-logo-admin'>menu <i className='fa-solid fa-bars'></i> </span>
                    </div>
                </div>



                <div className="menu-list-log-in-admin">
                    <ul>
                        <li>
                            <div className="solid-icon-admin-profile">
                                <a href="">
                                    {/* <i class="fa-solid fa-user"></i> */}
                                    <h4 style={{ color: "white" }}> {formData.inname}</h4>
                                </a>
                            </div>
                        </li>

                        <li>
                            <div className="solid-icon-admin-logout">

                                <a onClick={logOut}>
                                    <i class="fa-solid fa-sign-out"></i>
                                </a>

                            </div>
                        </li>
                    </ul>

                </div>

            </div>


            <div className={isOpen}>

                <div className="menu-list-admin">
                    <ul>
                        <li>   <h4 className='text-admin-head'>MAIN MENU</h4></li>
                        <hr />
                        <li>
                            <div className="solid-icon-admin">
                                <a href="/insprofile">
                                    <i class="fa-solid fa-house"></i>
                                    Profile View
                                </a>
                            </div>

                        </li>

                        <li id='drop-admin-1'>
                            <a>
                                <span className='solid-icon-admin'>
                                    <i class="fa-solid fa-building"></i>
                                    Course
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content4-admin">
                                    <a href="/inscourses">Create course</a>
                                    <a href="/mancourses">Manage Course</a>
                                    <a href="/delcourses">Delete Course</a>
                                </div>

                            </a>
                        </li>

                        <li id='drop-admin-6'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-calendar-days"></i>
                                    Events
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content6-admin">
                                    <a href="/createevent">Create Events</a>
                                    <a href="/manevents">Manage Events</a>
                                    <a href="/deleventsins">Delete Events</a>
                                </div>
                            </a>
                        </li>

                        <li>
                            <div className="solid-icon-admin">
                                <a href="/Apstudents">
                                    <i class="fa-solid fa-users"></i>
                                    Applied Students
                                </a>
                            </div>

                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-graduation-cap"></i>
                                    Student Loan
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content5-admin">
                                    <a href="/createstdloan">Create Student Loan</a>
                                    <a href="/manstdloan">Manage Student Loan</a>
                                    <a href="/delstdloanins">Delete Student Loan</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-graduation-cap"></i>
                                    SCHOLARSHIPS
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content5-admin">
                                    <a href="/createscholarship">Create Scholarship</a>
                                    <a href="/manscholarship">Manage Scholarship</a>
                                    <a href="/delscholarshipins">Delete Scholarship</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-laptop-file"></i>
                                    
                                    PROFESSIONAL<br />EXAMS
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>
                                <div className="dropdown-content5-admin">
                                    <a href="/createprofexams">Create Professional Exams</a>
                                    <a href="/manprofexams">Manage Professional Exam</a>
                                    <a href="/delprofexamsins">Delete Professional Exam</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-certificate"></i>
                                    LANGUAGE<br />QUALIFICATIONS
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>
                                <div className="dropdown-content5-admin">
                                    <a href="/createlangqual">Create Language Qualifications</a>
                                    <a href="/manlangqual">Manage Language Qualifications</a>
                                    <a href="/delinslangqual">Delete Language Qualifications</a>
                                </div>
                            </a>
                        </li>


                        {

                            formData.country === "Sri Lanka (ශ්‍රී ලංකාව)" && formData.type === "Government" ?
                                <li id='drop-admin-7'>
                                    <a>
                                        <span className="solid-icon-admin">
                                            <i class="fa-solid fa-graduation-cap"></i>
                                            External Degree
                                            <i class="fa-solid fa-sort-down"></i>
                                        </span>

                                        <div className="dropdown-content6-admin">
                                            <a href="/createexdeg">Create External Degree</a>
                                            <a href="/manexdeg">Manage External Degree</a>

                                        </div>
                                    </a>

                                </li>
                                :
                                <></>

                        }


                    </ul>
                </div>
            </div>
        </div>
    )
}

export default InstituteDashboard