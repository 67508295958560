import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard';
import '../../assets/css/admin/allcourses.css';
import axios from 'axios';

const AppliedForms = () => {
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get('http://localhost:8081/apply/appliedforms');
        setApplications(response.data);
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    };

    fetchApplications();
  }, []);

  return (
    <div>
      <AdminDashboard />

      <div className='AllViews-admin-course'>
        <div className="search-icon-admin admin-course-search">
          <h1>APPLIED FORMS</h1>
          <form>
            <input type="text" name="" id="" placeholder='Search Item' />
            <input type="submit" value="SEARCH" />
          </form>
        </div>

        <div className="scroller-box">
          <div className="table-course">
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Contact</th>
                  <th>DOB</th>
                  <th>Address</th>
                  <th>Email</th>
                  <th>Level of Study</th>
                  <th>Message</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {applications.map((app) => (
                  <tr key={app.id}>
                    <td>{app.firstName}</td>
                    <td>{app.lastName}</td>
                    <td>{app.contact}</td>
                    <td>{new Date(app.dob).toLocaleDateString('en-CA')}</td>
                    <td>{app.address}</td>
                    <td>{app.email}</td>
                    <td>{app.levelOfStudy}</td>
                    <td>{app.message}</td>
                    <td id='action-buttons'>
                    <a href={`mailto:${app.email}?subject=Reply to your application&body=Dear ${app.firstName},`} id='app-details'>REPLY</a>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppliedForms;
