import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/courses.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';

const ExternalDegree = () => {
  const [isCategoriesExpanded, setCategoriesExpanded] = useState(false);
  const [isLevelsExpanded, setLevelsExpanded] = useState(false);
  const [externalDegrees, setExternalDegrees] = useState([]);
  const [filters, setFilters] = useState({
    category: '',
    level: ''
  });
  const [displayedExternalDegreeCount, setDisplayedExternalDegreeCount] = useState(4);

  const navigate = useNavigate();

  const toggleCategories = () => setCategoriesExpanded(!isCategoriesExpanded);
  const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);

  const fetchExternalDegrees = async () => {
    try {
      const query = new URLSearchParams(filters).toString();
      const response = await fetch(`http://localhost:8081/externalDegree/all?${query}`);
      if (response.ok) {
        const data = await response.json();
        setExternalDegrees(data);
      } else {
        console.error('Failed to fetch external degrees');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchExternalDegrees();
  }, [filters]);

  const handleCourseClick = (id) => {
    navigate(`/courseview/${id}`);
  };

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value
    }));
  };

  const handleSeeAllCourses = () => {
    setDisplayedExternalDegreeCount((prevCount) => prevCount + 4);
  };

  const externalDegreesToShow = externalDegrees.slice(0, displayedExternalDegreeCount);

  return (
    <div>
      <SecondNav />
      <div className="Container">
        <div className="left-column">
          <div className="search-section">
            <h2>FIND YOUR COURSE</h2>
            <div className="Search-container">
              <input type="text" placeholder="What do you want to learn?" />
              <button type="submit"><i className="fa fa-search"></i></button>
            </div>
          </div>
          <div className={`Categories-section ${isCategoriesExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleCategories}>
              Category <span className="expand-arrow">{isCategoriesExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list ${isCategoriesExpanded ? 'active' : ''}`}>
              <li><input type="button" className="button-business" value="Business" onClick={() => handleFilterChange('category', 'Business')} /></li>
              <li><input type="button" className="button-science" value="Science" onClick={() => handleFilterChange('category', 'Science')} /></li>
              <li><input type="button" className="button-engineering" value="Engineering" onClick={() => handleFilterChange('category', 'Engineering')} /></li>
              <li><input type="button" className="button-arts" value="Arts" onClick={() => handleFilterChange('category', 'Arts')} /></li>
              <li><input type="button" className="button-IT" value="IT" onClick={() => handleFilterChange('category', 'IT')} /></li>
            </ul>
            <hr />
          </div>
          <div className={`Levels-section ${isLevelsExpanded ? 'active' : ''}`}>
            <h3 onClick={toggleLevels}>
              Levels <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
            </h3>
            <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
              <li><input type="button" className="button-foundation" value="Foundation" onClick={() => handleFilterChange('level', 'Foundation')} /></li>
              <li><input type="button" className="button-training" value="Training" onClick={() => handleFilterChange('level', 'Training')} /></li>
              <li><input type="button" className="button-certificate" value="Certificate" onClick={() => handleFilterChange('level', 'Certificate')} /></li>
              <li><input type="button" className="button-diploma" value="Diploma" onClick={() => handleFilterChange('level', 'Diploma')} /></li>
              <li><input type="button" className="button-advanced-diploma" value="Advanced Diploma" onClick={() => handleFilterChange('level', 'Advanced Diploma')} /></li>
              <li><input type="button" className="button-hnd" value="HND" onClick={() => handleFilterChange('level', 'HND')} /></li>
              <li><input type="button" className="button-degree" value="Degree" onClick={() => handleFilterChange('level', 'Degree')} /></li>
              <li><input type="button" className="button-bachelor" value="Bachelor" onClick={() => handleFilterChange('level', 'Bachelor')} /></li>
              <li><input type="button" className="button-postgraduate-diploma" value="Postgraduate Diploma" onClick={() => handleFilterChange('level', 'Postgraduate Diploma')} /></li>
              <li><input type="button" className="button-masters" value="Masters" onClick={() => handleFilterChange('level', 'Masters')} /></li>
              <li><input type="button" className="button-phd" value="PhD" onClick={() => handleFilterChange('level', 'PhD')} /></li>
            </ul>
          </div>
        </div>

        <div className="right-column">
          {externalDegreesToShow.map(externalDegree => (
            <div className="Course" key={externalDegree.id} onClick={() => handleCourseClick(externalDegree.id)}>
              <div className="calender">
                <div className='Date-top'></div>
                <div className="Date">{new Date(externalDegree.startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</div>
              </div>
              <div className="Course-info">
                <a href={`/edview/${externalDegree.id}`}><strong>{externalDegree.courseName}</strong></a>
                <p>Start Date: {new Date(externalDegree.startDate).toLocaleDateString('en-CA')}</p>
                <p>Duration: {externalDegree.duration}</p>
                <p>Level: {externalDegree.level}</p>
                <p>Field: {externalDegree.category}</p>
                <hr />
                <p1>Institute: <a href="#"><strong>{externalDegree.university}</strong></a></p1>
              </div>
            </div>
          ))}
          {displayedExternalDegreeCount < externalDegrees.length && (
            <button className="more-courses" onClick={handleSeeAllCourses}>SEE MORE COURSES</button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ExternalDegree;
