import React, { useState, useEffect } from 'react';
import '../assets/css/courses.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';

const AllForeignJobs = () => {
    const [isCategoriesExpanded, setCategoriesExpanded] = useState(false);
    const [isLevelsExpanded, setLevelsExpanded] = useState(false);
    const [isCountryExpanded, setCountryExpanded] = useState(false);
    const [fjobs, setfjobs] = useState([]);
    const [displayedfjobsCount, setDisplayedfjobsCount] = useState(4);

    const [filters, setFilters] = useState({
        fType: '', 
        country: ''
    });

    const toggleCategories = () => setCategoriesExpanded(!isCategoriesExpanded);
    const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);
    const toggleCountry = () => setCountryExpanded(!isCountryExpanded);

    

useEffect(() => {
    const fetchfjobs = async () => {
        try {
            const queryParams = new URLSearchParams({
                fType: filters.fType || '',
                country: filters.country || ''
            }).toString();

            const response = await fetch(`http://localhost:8081/fjobs/getall?${queryParams}`);
            if (response.ok) {
                const data = await response.json();
                setfjobs(data);
            } else {
                console.error('Failed to fetch jobs');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    fetchfjobs();
}, [filters]);


    
    const handleFilterChange = (type, value) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [type]: value
        }));
    };

    
    const filteredJobs = fjobs.filter(job => {
        const matchesType = filters.fType ? job.fType === filters.fType : true;
        const matchesCountry = filters.country ? job.country === filters.country : true;
        return matchesType && matchesCountry;
    });

    const fjobsToShow = filteredJobs.slice(0, displayedfjobsCount);

    const handleSeeAllfjobs = () => {
        setDisplayedfjobsCount((prevCount) => prevCount + 4);
    };


    return (
        <div> 
            <SecondNav/>
            <div className="Container">   
                <div className="left-column">
                    <div className="search-section">
                        <h2>FIND YOUR JOB</h2>
                        <div className="Search-container">
                            <input type="text" placeholder="What job are you looking for?" />
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </div>
                    <div className={`Categories-section ${isCategoriesExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleCategories}>
                            Job Category <span className="expand-arrow">{isCategoriesExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isCategoriesExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-IT" value="IT" onClick={() => handleFilterChange('name', 'IT')} /></li>
                            <li><input type="button" className="button-hnd" value="Healthcare" onClick={() => handleFilterChange('name', 'Healthcare')} /></li>
                            <li><input type="button" className="button-engineering" value="Engineering" onClick={() => handleFilterChange('name', 'Engineering')} /></li>
                            <li><input type="button" className="button-business" value="Business" onClick={() => handleFilterChange('name', 'Business')} /></li>
                            <li><input type="button" className="button-training" value="Education" onClick={() => handleFilterChange('name', 'Education')} /></li>
                        </ul>
                        <hr />
                    </div>
                    <div className={`Categories-section ${isLevelsExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleLevels}>
                            Job Type <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-foundation" value="Full-Time" onClick={() => handleFilterChange('fType', 'Full-Time')} /></li>
                            <li><input type="button" className="button-certificate" value="Part-Time" onClick={() => handleFilterChange('fType', 'Part-Time')} /></li>
                            <li><input type="button" className="button-degree" value="Internship" onClick={() => handleFilterChange('fType', 'Internship')} /></li>
                            <li><input type="button" className="button-diploma" value="Contract" onClick={() => handleFilterChange('fType', 'Contract')} /></li>
                            <li><input type="button" className="button-masters" value="Temporary" onClick={() => handleFilterChange('fType', 'Temporary')} /></li>
                        </ul>
                    </div>
                    <div className={`Categories-section ${isCountryExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleCountry}>
                        Country <span className="expand-arrow">{isCountryExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isCountryExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="buttonAustralia" value="Australia" onClick={() => handleFilterChange('country', 'Australia')} /></li>
                            <li><input type="button" className="buttonCanada" value="Canada" onClick={() => handleFilterChange('country', 'Canada')} /></li>
                            <li><input type="button" className="buttonChina" value="China" onClick={() => handleFilterChange('country', 'China')} /></li>
                            <li><input type="button" className="buttonFrance" value="France" onClick={() => handleFilterChange('country', 'France')} /></li>
                            <li><input type="button" className="buttonGermany" value="Germany" onClick={() => handleFilterChange('country', 'Germany')} /></li>
                            <li><input type="button" className="buttonIndia" value="India" onClick={() => handleFilterChange('country', 'India')} /></li>
                            <li><input type="button" className="buttonJapan" value="Japan" onClick={() => handleFilterChange('country', 'Japan')} /></li>
                            <li><input type="button" className="buttonUK" value="UK" onClick={() => handleFilterChange('country', 'UK')} /></li>
                            <li><input type="button" className="buttonUSA" value="USA" onClick={() => handleFilterChange('country', 'USA')} /></li>
                        </ul>
                        <hr />
                    </div>
                </div>

                <div className="right-column">
                    {fjobsToShow.map(fjobs => (
                        <div className="Course" key={fjobs.id}>
                           <div className="calender">
                                <div className='Date-top'></div>
                                <div className="Date">{new Date(fjobs.cdate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</div>
                            </div>
                            <div className="Course-info">
                                <a href={`/fjob/${fjobs.id}`}><strong>{fjobs.jobtit}</strong></a>
                                <p>Post Date: {new Date(fjobs.pdate).toLocaleDateString('en-CA')}</p>
                                <p>Type: {fjobs.fType}</p>
                                <p>Category: {fjobs.name}</p>
                                <p>Country: {fjobs.country}</p>
                                <hr />
                                <p1>Company: <a href="#"><strong>{fjobs.aname}</strong></a></p1>
                            </div>
                        </div>
                    ))}
                   {displayedfjobsCount < fjobs.length && (
                        <button className="more-courses" onClick={handleSeeAllfjobs}>SEE MORE FOREIGN JOBS</button>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AllForeignJobs