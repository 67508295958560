import React, { useState } from 'react'
import '../../assets/css/admin/login.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import Swal from 'sweetalert2'

const Changepass = () => {


    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const id = sessionStorage.getItem('id');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (password.password === '' || password.cpassword === '') {
            Swal.fire({
                title: "Please enter password",
                icon: "error",
            });
        } else if (password.password !== password.cpassword) {
            Swal.fire({
                title: "Password does not match",
                icon: "error",
            });
        } else if (passwordCheck1 === false || passwordCheck2 === false) {
            Swal.fire({
                title: "Please check password",
                icon: "error",
            })

        } else {

            axios.post('http://localhost:8081/common/change', { id: id, password: password.password }).then((response) => {

                Swal.fire({
                    title: "Update Successfull",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false,
                }).then(() => {
                    sessionStorage.clear();
                    window.location.href = '/rlogin'
                });

            })



        }


    }

    const [password, setPassword] = useState({
        password: '',
        cpassword: ''
    })

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        setPassword({
            ...password,
            [name]: value
        })



    }

    const [err, setErr] = useState('')
    const [colorBox, setColor] = useState('red')
    const [passwordCheck1, setPasswordCheck1] = useState(false)
    const [passwordCheck2, setPasswordCheck2] = useState(false)

    const passwordCheck = (pass) => {
        setColor('red')
        if (pass.length < 8) {
            setErr('Password must be at least 8 characters');
        } else if (!/[A-Z]/.test(pass)) {
            setErr('Password must contain at least one uppercase letter');
        } else if (!/[a-z]/.test(pass)) {
            setErr('Password must contain at least one lowercase letter');
        } else if (!/[0-9]/.test(pass)) {
            setErr('Password must contain at least one digit');
        } else if (!/[!@#$%^&*]/.test(pass)) {
            setErr('Password must contain at least one special character');
        } else {
            setErr('Password is valid')
            setColor('green')
            setPasswordCheck1(true)
            setPassword({
                ...password,
                password: pass
            })
        }
    }


    const cpasswordCheck = (pass) => {
        setColor('red')

        if (passwordCheck1 === true) {
            if (password.password === ' ') {
                setErr('Please enter password first');
            } else if (password.password !== pass) {
                setErr('Password does not match');
            } else {
                setErr('Password is valid')
                setColor('green')
                setPasswordCheck2(true)
                setPassword({
                    ...password,
                    cpassword: pass
                })
            }
        } else {
            setErr('Please check password first');
        }

    }




    const style = {
        fontFamily: '"Noto Sans", sans-serif',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '0.8rem',
        textAlign: "left"
    }

    const style1 = {
        marginBottom: "20px",
        marginTop: "20px"
    }




    return (
        <div>
            <div className="login-container">
                <div className="login-box">
                    <div className="logo-section">

                        <a href="/"></a>
                    </div>
                    <h3 style={{ textTransform: 'uppercase', fontSize: '1.6rem', marginBottom: "20px" }}>Change Password</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="input-group password-container" style={style1}>
                            <label style={style} htmlFor="password">Password</label>
                            <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                name="password"
                                required
                                value={password.password}
                                onChange={handleChange}
                                onKeyUp={(e) => {
                                    passwordCheck(e.target.value)
                                }}
                            />
                            <span
                                className="toggle-visibility"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? <FaEye /> : < FaEyeSlash />}
                            </span>
                        </div>
                        <div className="input-group password-container" >
                            <label style={style} htmlFor="cpassword">confirm Password</label>
                            <input
                                type={showPassword ? "text" : "password"}
                                id="cpassword"
                                name="cpassword"
                                required
                                value={password.cpassword}
                                onChange={handleChange}
                                onKeyUp={(e) => {
                                    cpasswordCheck(e.target.value)
                                }}
                            />
                            <span
                                className="toggle-visibility"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? <FaEye /> : < FaEyeSlash />}
                            </span>
                        </div>


                        <p style={style}> <span style={{ color: colorBox }}>{err}</span></p>

                        <button type="submit" className="login-button" style={{ width: "100%" }}>UPDATE</button>
                    </form>

                </div>
            </div>
        </div>
    )
}

export default Changepass