import React from "react";
import '../assets/css/aboutus.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';

const AboutUs = () => {
  return (
    <div>
      <SecondNav />
      <div className="about-us">
        <section className="intro">
          <h1>ABOUT US</h1>
          <h2>Welcome to SKILLS SRI LANKA!</h2>
          <div className="vision-mission">
            <div className="vision">
              <h3>Our Vision</h3>
              <p>"A Skilled educated Workforce"</p>
            </div>
            <div className="mission">
              <h3>Our Mission</h3>
              <p>To empower every student with access to the resources, information, and opportunities needed to pursue higher education, vocational education, and successful careers, thereby leveling the educational playing field and fostering a future where every student can thrive.</p>
            </div>
          </div>
        </section>

        <section className="discover">
          <div className="discover-content">
            <div className="discover-text">
              <h2>Discover SKILLS SRI LANKA</h2>
              <p>At SKILLS SRI LANKA, we harness modern technology for career guidance. Our groundbreaking AI-generated Career Assessment Tool assesses students’ inherent skills and passions with unprecedented precision, offering tailored career guidance that aligns with the latest trends and demands of the global job market. We integrate career guidance with the United Nations’ Sustainable Development Goals (SDGs), emphasizing quality education, decent work, economic growth, reduced inequalities, and industry innovation. This approach fosters global citizenship and responsibility among participants, ensuring inclusivity and equal opportunity, particularly for women and underprivileged youth.</p>
              <p>Our comprehensive career guidance process includes:</p>
              <ul>
                <li><strong>Self-Understanding:</strong> Helping students scientifically assess their skills and passions for informed career decision-making.</li>
                <li><strong>Career Awareness:</strong> Providing insights into diverse careers and industries, guided by experts.</li>
                <li><strong>Higher Education Networking:</strong> Offering awareness about higher education options and guidance on securing scholarships.</li>
                <li><strong>Employment Support and Skills Development:</strong> Tailored support for transitioning into employment and developing critical soft skills.</li>
              </ul>
              <div className="discover-image"></div>
              <p>Our annual initiatives, like the SKILLS SRI LANKA Higher Education and Career Guidance Exhibition Series, are designed to open new horizons for students by showcasing a variety of higher education, skills development, and career opportunities. These events provide an in-depth understanding of global and local educational options and offer comprehensive career guidance solutions free of charge.</p>
              <p>We also support educators by equipping them with the knowledge and tools to integrate modern career guidance practices into their teaching, enriching the students’ learning experience.</p>
              <p>SKILLS SRI LANKA is dedicated to empowering the youth of Sri Lanka through innovative educational solutions and strategic collaborations. By leveraging modern technology and working closely with government and industry leaders, we strive to equip our students with the skills and knowledge needed to thrive in a dynamic global workforce.</p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
