import React, { useState, useEffect } from 'react';
import '../assets/css/courses.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';

const AllLocalJobs = () => {
    const [isLevelsExpanded, setLevelsExpanded] = useState(false);
    const [ljobs, setljobs] = useState([]);
    const [displayedfjobsCount, setDisplayedfjobsCount] = useState(4);

    const [filters, setFilters] = useState({
        jobtype: '',    
        joblevel: ''  
    });

    const toggleLevels = () => setLevelsExpanded(!isLevelsExpanded);

    useEffect(() => {
        const fetchljobs = async () => {
            try {
                const queryParams = new URLSearchParams({
                    jobtype: filters.jobtype || '',
                    joblevel: filters.joblevel || ''
                }).toString();

                const response = await fetch(`http://localhost:8081/ljobs/getalljobs?${queryParams}`);
                if (response.ok) {
                    const data = await response.json();
                    setljobs(data);
                } else {
                    console.error('Failed to fetch jobs');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchljobs();
    }, [filters]);

    const handleFilterChange = (type, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [type]: value
        }));
    };

    const filteredJobs = ljobs.filter(job => {
        const matchesType = filters.jobtype ? job.jobtype === filters.jobtype : true;
        const matchesJobLevel = filters.joblevel ? job.joblevel === filters.joblevel : true;
        return matchesType && matchesJobLevel;
    });

    const fjobsToShow = filteredJobs.slice(0, displayedfjobsCount);

    const handleSeeAllfjobs = () => {
        setDisplayedfjobsCount((prevCount) => prevCount + 4);
    };

    return (
        <div>
            <SecondNav />
            <div className="Container">
                <div className="left-column">
                    <div className="search-section">
                        <h2>FIND A JOB</h2>
                        <div className="Search-container">
                            <input type="text" placeholder="What job are you looking for?" />
                            <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                    </div>

                    <div className={`Categories-section ${isLevelsExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleLevels}>
                            Job Type <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-foundation" value="Full-Time" onClick={() => handleFilterChange('fType', 'Full-Time')} /></li>
                            <li><input type="button" className="button-certificate" value="Part-Time" onClick={() => handleFilterChange('fType', 'Part-Time')} /></li>
                            <li><input type="button" className="button-degree" value="Internship" onClick={() => handleFilterChange('fType', 'Internship')} /></li>
                            <li><input type="button" className="button-diploma" value="Contract" onClick={() => handleFilterChange('fType', 'Contract')} /></li>
                            <li><input type="button" className="button-masters" value="Temporary" onClick={() => handleFilterChange('fType', 'Temporary')} /></li>
                        </ul>
                    </div>

                    <div className={`Categories-section ${isLevelsExpanded ? 'active' : ''}`}>
                        <h3 onClick={toggleLevels}>
                            Job Level <span className="expand-arrow">{isLevelsExpanded ? '▲' : '▼'}</span>
                        </h3>
                        <ul className={`filter-list ${isLevelsExpanded ? 'active' : ''}`}>
                            <li><input type="button" className="button-IT" value="Entry-Level" onClick={() => handleFilterChange('jobLevel', 'Entry-Level')} /></li>
                            <li><input type="button" className="button-hnd" value="Mid-Level" onClick={() => handleFilterChange('jobLevel', 'Mid-Level')} /></li>
                            <li><input type="button" className="button-business" value="Senior-Level" onClick={() => handleFilterChange('jobLevel', 'Senior-Level')} /></li>
                        </ul>
                    </div>
                </div>

                <div className="right-column">
                    {fjobsToShow.map(ljobs => (
                        <div className="Course" key={ljobs.id}>
                            <div className="calender">
                                <div className='Date-top'></div>
                                <div className="Date">{new Date(ljobs.cdate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</div>
                            </div>
                            <div className="Course-info">
                                <a href={`/localjview/${ljobs.id}`}><strong>{ljobs.jobtit}</strong></a>
                                <p>Post Date: {new Date(ljobs.pdate).toLocaleDateString('en-CA')}</p>
                                <p>Type: {ljobs.jobtype}</p>
                                <p>Level: {ljobs.joblevel}</p>
                                <hr />
                                <p1>Company: <a href="#"><strong>{ljobs.cname}</strong></a></p1>
                            </div>
                        </div>
                    ))}
                    {displayedfjobsCount < ljobs.length && (
                        <button className="more-courses" onClick={handleSeeAllfjobs}>SEE MORE JOBS</button>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AllLocalJobs;
