import React, { useState } from 'react'
import '../assets/css/admin/login.css';
import Img from '../assets/images/logo1.jpeg';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import Swal from 'sweetalert2'


const RLogin = () => {

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        const password = e.target.password.value;
        const role = e.target.role.value;


        if (role === "ins") {

            axios.post('http://localhost:8081/common/login', { email: email, password: password }).then((response) => {

                if (response.data.message === "Invalid") {
                    Swal.fire({
                        title: "Invalid Email or Password",
                        icon: "error",
                    });

                } else {
                    Swal.fire({

                        title: "Login Successfull",
                        icon: "success",
                        timer: 1500,
                        showConfirmButton: false,
                    }).then(() => {
                        if (response.data.country !== "Sri Lanka (ශ්‍රී ලංකාව)") {
                            window.location.href = "/sadashboard";
                        } else {
                            window.location.href = "/insprofile"
                        }
                    });
                    // set session storage
                    sessionStorage.setItem('id', response.data.id);
                    sessionStorage.setItem('email', response.data.email);
                    sessionStorage.setItem('in_name', response.data.in_name);
                }
            }).catch((err) => {
                console.log(err);


            })



        } else if (role === "gc") {


            axios.post('http://localhost:8081/govofficer/login', { email: email, pass: password }).then((response) => {
                if (response.data.message === "Invalid") {
                    Swal.fire({
                        title: "Invalid Email or Password",
                        icon: "error",
                    });

                } else {
                    Swal.fire({

                        title: "Login Successfull",
                        icon: "success",
                        timer: 1500,
                        showConfirmButton: false,
                    }).then(() => {
                        window.location.href = "/gofficer";
                    });
                    // set session storage
                    sessionStorage.setItem('id', response.data.id);
                    sessionStorage.setItem('email', response.data.email);
                    sessionStorage.setItem('gc_name', response.data.name);
                    sessionStorage.setItem('district', response.data.district);
                }
            }).catch((err) => {
                Swal.fire({
                    title: "Invalid Email or Password",
                    icon: "error",
                });

            })

        } else {
            Swal.fire({
                title: "Select Role",
                icon: "error",
            });
        }



    }

    const selectStyle = {
        width: "100%",
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        marginBottom: "20px",
        marginTop: "5px",
        color: "#333",
        fontSize: "16px",
        backgroundColor: "#f9f9f9",
        outline: "none",
    }

    return (
        <div> <div className="login-container">
            <div className="login-box">
                <div className="logo-section">
                    <a href="/"><img src={Img} alt="logo.png" width={50} /></a>
                </div>
                <h3>LOGIN</h3>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />
                    </div>
                    <div className="input-group password-container">
                        <label htmlFor="password">Password</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            required
                        />
                        <span
                            className="toggle-visibility"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? <FaEye /> : < FaEyeSlash />}
                        </span>
                    </div>

                    <div className="input-group password-containe">
                        <label htmlFor="role">Role</label>
                        <select name="role" id="role" style={selectStyle} required>
                            <option value="null">Select Role</option>
                            <option value="ins">Institute</option>
                            <option value="gc">Government Officer</option>
                        </select>
                    </div>

                    <div className="input-group">
                        <a href="/forgot" style={{color:"blue", textTransform:"uppercase", fontSize:"0.8rem"}}  >Forgot Password</a>
                    </div>

                    <button type="submit" className="login-button" style={{ width: "100%" }}>Sign In</button>
                </form>



            </div>
        </div>
        </div>
    )
}

export default RLogin