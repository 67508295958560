import React, { useState } from 'react'
import Img from '../../assets/images/skillslogo.png'
import '../../assets/css/admin/Admin-Dashboard.css'

const AdminDashboard = () => { 

    const [isOpen, setOpen] = useState('side-nav-admin');

    const handleOpen = (e) => {
        e.preventDefault();
        setOpen(isOpen === 'side-nav-admin' ? 'side-nav-admin side-open' : 'side-nav-admin');
    };

    const name = sessionStorage.getItem('aname');

    if (!name || !sessionStorage.getItem('aid')) {
        window.location.href = "/login";
    }

    const navigateToForm = () => {
        window.location.href = "/bankcreatestdloan";
    };



    return (
        <div id='admin-dashboard'>

            <div className="nav-bar-admin">


                {/* logo section */}
                <div className="logo-section logo-section-admin">
                    <a href="/">  <img src={Img} alt="logo.png" width={150} /></a>

                    <div className="menu" onClick={handleOpen}>
                        <span className='menu-logo-admin'>menu <i className='fa-solid fa-bars'></i> </span>
                    </div>
                </div>



                <div className="menu-list-log-in-admin">
                    <ul>
                        <li>
                            <div className="solid-icon-admin-profile">
                                <h4 style={{textTransform:"uppercase"}}>{name}</h4>
                            </div>
                        </li>

                        <li>
                            <div className="solid-icon-admin-logout" onClick={() => {
                                sessionStorage.removeItem('aid');
                                sessionStorage.removeItem('aname');
                                window.location.href = "/login"
                            }}>
                               <a href="">
                               <i class="fa-solid fa-sign-out"></i>
                               </a>
                            </div>
                        </li>
                    </ul>

                </div>

            </div>


            <div className={isOpen}>

                {/* logo section
                <div className="logo-section logo-section-admin">
                    <a href="/">  <img src={Img} alt="logo.png" /></a>
                </div> */}

                <div className="menu-list-admin">
                    <ul>
                        {/* <li>   <h4 className='text-admin-head'>MAIN MENU</h4></li>
                        <hr /> */}
                        <li>
                            <div className="solid-icon-admin">
                                <a href="/admindc">
                                    <i class="fa-solid fa-house"></i>
                                    Dashboard
                                </a>
                            </div>
                        </li>

                        <li id='drop-admin-1'>
                            <a>
                                <span className='solid-icon-admin'>
                                    <i class="fa-solid fa-user"></i>
                                    Students
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content4-admin">
                                    <a href="/appliedforms">Applied Forms</a>
                                    <a href="/allstudents">All Students</a>
                                    <a href="/penstudents">Pending Students</a>
                                    <a href="/delstudents">Delete Students</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-1'>
                            <a>
                                <span className='solid-icon-admin'>
                                    <i class="fa-solid fa-building"></i>
                                    Institutes
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content4-admin">
                                    <a href="/allinstitute">All Institutes</a>
                                    <a href="/peninstitute">Pending Institutes</a>
                                    <a href="/delInstitute">Delete Institutes</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-book"></i>
                                    Courses
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content5-admin">
                                    <a href="/allcourses">All Courses</a>
                                    <a href="/pendingcourses">Pending Courses</a>
                                    <a href="/deletecourses">Delete Courses</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-book"></i>
                                    Foreign Courses
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content5-admin">
                                    <a href="/saallcourses">All Courses</a>
                                    <a href="/sapendingcourses">Pending Courses</a>
                                    <a href="/sadeletecourses">Delete Courses</a>
                                </div>
                            </a>
                        </li>

                        
                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-book"></i>
                                    External Degrees
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content5-admin">
                                    <a href="/allexternaldeg">All External Degrees</a>
                                    <a href="/pendingexternaldeg">Pending External Degrees</a>
                                    <a href="/delexternaldeg">Delete External Degrees</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-book"></i>
                                   Tuitions
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content5-admin">
                                    <a href="/admincreatetuition">Create Tuitions</a>
                                    <a href="/alltuition">All Tuitions</a>
                                    <a href="/pendingtuition">Pending Tuitions</a>
                                    <a href="/deltuition">Delete Tuitions</a>
                                </div>
                            </a>
                        </li>
                        
                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-graduation-cap"></i>
                                    SCHOLARSHIPS
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content5-admin">
                                    <a href="/allscholarships">All Scholarship</a>
                                    <a href="/pendingscholarships">Pending Scholarship</a>
                                    <a href="/deletescholarships">Delete Scholarship</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-building"></i>
                                    International <br /> Schools
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content5-admin">
                                    <a href="/intschlprofile">Profile View</a>
                                    <a href="/allintschl">All International Schools</a>
                                    <a href="/pendingsintschl">Pending International Schools</a>
                                    <a href="/deleteintschl">Delete International Schools</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-6'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-calendar-days"></i>
                                    Events
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content6-admin">
                                    <a href="/allevents">All Events</a>
                                    <a href="/pendingevents">Pending Events</a>
                                    <a href="/delevents">Delete Events</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                <i className="fa-solid fa-graduation-cap"></i>
                                Student Loan
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content5-admin">
                                <button onClick={navigateToForm} className="admin-btn">
                                    Bank Student Loan
                                </button>
                                <a href="/allbankstdloan">All Bank Loans</a>
                                <a href="/pendingbankstdloan">Pending Bank Loans</a>
                                <a href="/delbankstdloan">Delete Bank Loans</a>

                                <a href="/allstdloan">All Student Loans</a>
                                <a href="/pendingstdloan">Pending Student Loans</a>
                                <a href="/delstdloan">Delete Student Loans</a>
                            </div>
                            </a>
                        </li>

                        <li id='drop-admin-7'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-gift"></i>
                                    Jobs
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content6-admin">
                                    <a href="/localjoba">Local Jobs</a>
                                    <a href="/allfjob">Foreign Jobs</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-gift"></i>
                                    Gov. officer
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content5-admin">
                                <a href="/cgofficer">ADD gov_officer</a>
                                <a href="/allgc">ALL gov_officers</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-list-alt"></i>
                                   APPLICATIONS
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>

                                <div className="dropdown-content5-admin">
                                    <a href="/admincreateapplication">Create Applications</a>
                                    <a href="/allapplications">All Applications</a>
                                    <a href="/pendingapplications">Pending Applications</a>
                                    <a href="/delapplications">Delete Applications</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-laptop-file"></i>
                                    
                                    PROFESSIONAL<br />EXAMS
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>
                                <div className="dropdown-content5-admin">
                                    <a href="/allprofexams">All Professional Exams</a>
                                    <a href="/pendingprofexams">Pending Professional Exams</a>
                                    <a href="/deleteprofexams">Delete Professional Exams</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-certificate"></i>
                                    LANGUAGE<br />QUALIFICATIONS
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>
                                <div className="dropdown-content5-admin">
                                    <a href="/alllangqual">All Language Qualifications</a>
                                    <a href="/pendinglangqual">Pending Language Qualifications</a>
                                    <a href="/dellangqual">Delete Language Qualifications</a>
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-certificate"></i>
                                    Manage Category<br />
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>
                                <div className="dropdown-content5-admin">
                                    <a href="/fjobcate">job category</a>
                                    {/* <a href="/pendinglangqual">Pending Language Qualifications</a>
                                    <a href="/dellangqual">Delete Language Qualifications</a> */}
                                </div>
                            </a>
                        </li>

                        <li id='drop-admin-2'>
                            <a href='/advertise'>
                                <span className="solid-icon-admin">
                                    <i class="fa-solid fa-certificate"></i>
                                    Manage<br></br> Advertisement<br />
                                    <i class="fa-solid fa-sort-down"></i>
                                </span>
                               
                            </a>
                        </li>
                        

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AdminDashboard