import React, { useEffect, useState } from 'react'
import axios from 'axios'

const Governmentofficer = () => {

  const [searchTerm, setSearchTerm] = useState('')
  const [students, setStudents] = useState([])

  const id = sessionStorage.getItem('id');

  if (!sessionStorage.getItem('id')) {
    window.location.href = '/rlogin'
  }

  const district = sessionStorage.getItem('district');

  useEffect(() => {

    axios.get('http://localhost:8081/govofficer/district/' + district).then((response) => {
      setStudents(response.data)
      console.log(response.data)
    }).catch((err) => {
      console.log(err);
    })


  }, [students])




  const handleSearch = (e) => {
    e.preventDefault()
    setSearchTerm(e.target.value)
  }

  const filteredInstitutes = students.filter((institute) =>
    institute.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div id="gcd">

      <nav>
        <div class="nav-gc">
          <h3 className="g-text">Government Officer</h3>
          <ul id="nav-mobile-gc" class="right hide-on-med-and-down">

            <li>
              <button onClick={() => {
                sessionStorage.clear();
                window.location.href = "/rlogin"
              }}>
                LOG OUT
              </button>
            </li>

          </ul>
        </div>
      </nav>

      <div className="box-gc ">

        <div className="search-icon-admin admin-institute-search">

          <h1>PENDING INSTITUTE</h1>
          <form >
            <input type="text" name="" onChange={handleSearch} id="" placeholder='Search Item' />
            <input type="submit" value="SEARCH" />
          </form>

        </div>

        <div className="scroller-box">
          <div className="table-institute">
            <table>
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th>District</th>
                  <th>Province</th>
                  <th>Mobile</th>
                  <th>School</th>
                  <th>Course</th>
                  <th>Category</th>
                  <th>Message</th>
                </tr>
              </thead>

              <tbody>

                {
                  filteredInstitutes.map((student) => {
                    return (
                      <tr key={student.id}>
                        <td>{student.name}</td>
                        <td>{student.email}</td>
                        <td>{student.address}</td>
                        <td>{student.district}</td>
                        <td>{student.province}</td>
                        <td>{student.mobile}</td>
                        <td>{student.school}</td>
                        <td>{student.course}</td>
                        <td>{student.category}</td>
                        <td>{student.message}</td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </table>

          </div>

        </div>

      </div>
    </div>



  )
}

export default Governmentofficer