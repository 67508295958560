import React, { useEffect, useState } from 'react';
import '../assets/css/styles.css';
import '../assets/css/secondnav.css';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import axios from 'axios';


const Main = () => {
  const [startingSoonCourses, setStartingSoonCourses] = useState([]);

  useEffect(() => {
    const fetchStartingSoonCourses = async () => {
      try {
        const response = await fetch('/api/courses/starting-soon');
        const data = await response.json();
        setStartingSoonCourses(data);
      } catch (error) {
        console.error('Error fetching starting soon courses:', error);
      }
    };

    fetchStartingSoonCourses();
  }, []);

  

  const [data, setData] = useState([]);
  const [home1, setHome1] = useState('');
  const [home2, setHome2] = useState('');

  useEffect(() => {
    axios.get('http://localhost:8081/advertisement/getAll').then((res) => {
   
        setData(res.data);
        console.log(res.data);

        // Check the positions in the response data directly
        res.data.forEach((item) => {
          if (item.Position === 'home1') {
            setHome1(item.img);
          } else if (item.Position === 'home2') {
            setHome2(item.img);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
   



  return (
    <main>

     
      <NavigationBar />
      <div className="search-container">
        <input type="text" placeholder="Find your course" />
        <button type="submit" onClick={(e) => {
          window.location.href = "/govcourses"
        }}><i className="fa fa-search"></i></button>
      </div>

      <div className="advertisement">
        <div className="advertisement-small"><img src={`http://localhost:8081/Upload/Images/${home1}`} style={{width:"100%", height:"100%"}} /> </div>
      </div>

      <div className="categories-ad-container">
        <div className="categories left">

          <li id='d0'>
            <a className="category green-dark">FIND A JOB <i className="fa-solid fa-caret-down"></i></a>
            <div className="dd0">
              <a href="/alllocaljobs">Local Jobs</a>
              <a href="/allforeignjobs">Foreign Jobs</a>
            </div>
          </li>

          <li id='d'>
            <a className="category pink">TVEC COURSES <i className="fa-solid fa-caret-down"></i></a>
            <div className="dd">
              <a href="#" target="_blank" rel="noopener noreferrer">What is TVEC ?</a>
              <a href="https://www.tvec.gov.lk" target="_blank" rel="noopener noreferrer">TVEC Services</a>
              <a href="http://www.nvq.gov.lk/TVET_GUIDE/" target="_blank" rel="noopener noreferrer">TVEC Courses</a>
            </div>
          </li>

          <li id='d1'>
            <a className="category blue">GOVERNMENT UNIVERSITY <i className="fa-solid fa-caret-down"></i></a>
            <div className="dd1">
              <a href="https://www.ugc.ac.lk" target="_blank" rel="noopener noreferrer">University Grants Commission (Sri Lanka)</a>
              <a href="/govuni">Find Government Universities</a>

            </div>
          </li>

          <li id='d6'>
            <a className="category red">INTERNATIONAL SCHOOLS <i className="fa-solid fa-caret-down"></i></a>
            <div className="dd6">
              <a href="/registerintschls">Register Now</a>
              <a href="/intschls">Find International Schools</a>
            </div>
          </li>

          <li id='d2'>
            <a className="category green">TUITION <i className="fa-solid fa-caret-down"></i></a>
            <div className="dd2">
              <a href="/createtuition">Create Tuition</a>
              <a href="/tuition">Find Tuition</a>
            </div>
          </li>

          <a href="/languagequalifications" className="category blue-light">LANGUAGE QUALIFICATIONS</a>
        </div>

        <div className="advertisement-large">
        <img src={`http://localhost:8081/Upload/Images/${home2}`} style={{width:"100%", height:"100%"}} /> 
        </div>

        <div className="categories right">
          <a href="/scholarships" className="category orange">SCHOLARSHIPS</a>

          <li id='d3'>
            <a className="category blue-light">CAREER TESTS <i className="fa-solid fa-caret-down"></i></a>
            <div className="dd3">
              <a href="#" target="_blank" rel="noopener noreferrer">What is Career Test?</a>
              <a href="http://www.youthjobs.lk/career-key.html" target="_blank" rel="noopener noreferrer">Start Free Career Test</a>
              <a href="https://www.lankaeducator.com/ctest/index.html" target="_blank" rel="noopener noreferrer">Career Guide Test NIE</a>
            </div>
          </li>

          <a href="/courses" className="category yellow">ONLINE COURSES</a>

          <li id='d4'>
            <a className="category pink-dark">APPLICATIONS <i className="fa-solid fa-caret-down"></i></a>
            <div className="dd4">
              <a href="/createapplication">Create Applications</a>
              <a href="/applications">Find Applications</a>
            </div>
          </li>

          <li id='d5'>
            <a className="category green-dark">GOVERNMENT GAZETTE <i className="fa-solid fa-caret-down"></i></a>
            <div className="dd5">
              <a href="#" target="_blank" rel="noopener noreferrer">Government Printing Gazette</a>
              <a href="https://archives.dailynews.lk/2001/pix/gov_gazette.html" target="_blank" rel="noopener noreferrer">Daily News Gazette</a>
            </div>
          </li>

          <a href="#" className="category purple">GUIDANCE PROGRAM</a>

        </div>
      </div>

      <div className="courses">
        <h2>COURSES STARTING SOON...</h2>
        <div className="course-list">
          {startingSoonCourses.map(course => (
            <div key={course.id} className="course-item">
              <div className="calendar">
                <div className="date-top"></div>
                <div className="date">{new Date(course.startDate).toLocaleDateString('en-GB', { month: 'short', day: 'numeric' })}</div>
              </div>
              <div className="course-title">
                <a href="#"><strong>{course.courseName}</strong></a>
              </div>
              <div className="course-details">
                Start Date: {new Date(course.startDate).toLocaleDateString('en-GB')}<br />
                Duration: {course.duration} Hrs
              </div>
              <hr />
              <div className="course-ins">By: <a href="#">{course.instituteName}</a></div>
            </div>
          ))}
        </div>
        <a href="/courses" className="see-all-courses">SEE ALL COURSES</a>
      </div>
      <Footer />
    </main>
  );
};

export default Main;
