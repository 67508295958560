import React, { useEffect, useState, useMemo } from 'react';
import '../../assets/css/studyabroad/sacreate.css';
import SADashboard from './SADashboard';
import axios from 'axios';
import countryList from 'react-select-country-list';

const SACreate = () => {
  const institute_id = sessionStorage.getItem('id');

  const [formData, setFormData] = useState({
    country: '',
    university: '',
    courseName: '',
    level: '',
    duration: '',
    startDate: '',
    studyArea: '',
    specification: '',
    careerOpportunities: '',
    industry: '',
    cologo: null,
    codescription: '',
    instituteName: '',
    instituteAddress: '',
    institutePhone: '',
    instituteEmail: '',
    instituteLocation: ''
  });

  const options = useMemo(() => countryList().getData(), []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      setFormData(prevData => ({
        ...prevData,
        [name]: files[0]
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  useEffect(() => {
    const fetchInstituteDetails = async () => {
      try {
        const response = await axios.get(`http://localhost:8081/ins/one/${institute_id}`);
        if (response.data && response.data.length > 0) {
          const institute = response.data[0];
          setFormData(prevData => ({
            ...prevData,
            instituteName: institute.in_name || '',
            instituteAddress: institute.address || '',
            institutePhone: institute.tel || '',
            instituteEmail: institute.email || '',
            instituteLocation: institute.location || ''
          }));
        }
      } catch (error) {
        console.error('Error fetching institute details:', error);
      }
    };
    fetchInstituteDetails();
  }, [institute_id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
  
    for (const key in formData) {
      if (key !== 'cologo') {
        formDataToSend.append(key, formData[key]);
      }
    }
  
    if (formData.cologo) {
      formDataToSend.append('cologo', formData.cologo);
    }
  
    // Add the institute ID to the form data
    formDataToSend.append('institute_id', institute_id);
  
    try {
      const response = await fetch('http://localhost:8081/studyabroad/create', {
        method: 'POST',
        body: formDataToSend
      });
  
      const responseText = await response.text(); 
  
      if (response.ok) {
        const data = JSON.parse(responseText); // Try parsing the response as JSON
        console.log('Form submitted:', data);
        window.alert('Study abroad course created successfully!');
      } else {
        console.error('Server error:', responseText);
        window.alert('Failed to create study abroad course. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      window.alert('Failed to create study abroad course. Please try again.');
    }
  };
  

  return (
    <main className="study-abroad-create">
      <SADashboard />
      <div className="sa-head-content-text">
        <h4>Study Abroad - Create Course</h4>
      </div>
      <div className="sa-container">
        <form onSubmit={handleSubmit}>
          <div className="new">
            <div className="formgroup">
              <i className="fas fa-globe"></i>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="" disabled>Select Country</option>
                {options.map(option => (
                  <option key={option.value} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-university"></i>
              <input
                type="text"
                id="university"
                name="university"
                value={formData.university}
                onChange={handleChange}
                placeholder="University/Institute/High School"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-book"></i>
              <input
                type="text"
                id="courseName"
                name="courseName"
                value={formData.courseName}
                onChange={handleChange}
                placeholder="Course Name/Degree Name"
              />
            </div>

            <div className="formgroup">
              <i className="fa fa-money-bill-alt"></i>
              <input type="text" id="courseFee" name="courseFee" value={formData.courseFee} onChange={handleChange} placeholder="Course Fee" />
            </div>
            
            <div className="formgroup">
            <i className="fas fa-book-reader"></i>
              <select
                id="category"
                name="category"
                value={formData.level}
                onChange={handleChange}
              >
                <option value="" disabled>Select Course Category</option>
                <option value="Business">Business</option>
                <option value="Engineering">Engineering</option>
                <option value="Science">Science</option>
                <option value="Arts">Arts</option>
                <option value="Technology">Technology</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-layer-group"></i>
              <select
                id="level"
                name="level"
                value={formData.level}
                onChange={handleChange}
              >
                <option value="" disabled>Select Course Level</option>
                <option value="Training">Training</option>
                <option value="Certificate">Certificate</option>
                <option value="Diploma">Diploma</option>
                <option value="Advanced Diploma">Advanced Diploma</option>
                <option value="HND">HND</option>
                <option value="Degree">Degree</option>
                <option value="Postgraduate Diploma">Postgraduate Diploma</option>
                <option value="Master's">Master's</option>
                <option value="PhD">PhD</option>
              </select>
            </div>

            <div className="formgroup">
              <i className="fas fa-hourglass-start"></i>
              <input
                type="text"
                id="duration"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                placeholder="Duration"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-calendar-alt"></i>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                placeholder="Start Date"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-cogs"></i>
              <input
                type="text"
                id="specification"
                name="specification"
                value={formData.specification}
                onChange={handleChange}
                placeholder="Specification"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-briefcase"></i>
              <input
                type="text"
                id="careerOpportunities"
                name="careerOpportunities"
                value={formData.careerOpportunities}
                onChange={handleChange}
                placeholder="Career Opportunities"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-industry"></i>
              <input
                type="text"
                id="industry"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                placeholder="Industry"
              />
            </div>

            <div className="formgroup">
              <label htmlFor="file-input" className="drop-container">
                <span className="drop-title">Drop Course Banner</span>
                <input
                  type="file"
                  accept="image/*"
                  id="file-input"
                  name="cologo"
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="formgroup textarea">
              <textarea
                name="codescription"
                placeholder="Enter the description of the Course background........"
                onChange={handleChange}
                value={formData.codescription}
              ></textarea>
            </div>

            {/* Contact Details */}
            <div className="formgroup">
              <h4>Contact Details</h4>
            </div>

            <div className="formgroup">
              <i className="fas fa-building"></i>
              <input
                type="text"
                id="instituteName"
                name="instituteName"
                value={formData.instituteName}
                onChange={handleChange}
                placeholder="Name of Institute"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input
                type="text"
                id="instituteAddress"
                name="instituteAddress"
                value={formData.instituteAddress}
                onChange={handleChange}
                placeholder="Institute Address"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-phone"></i>
              <input
                type="tel"
                id="institutePhone"
                name="institutePhone"
                value={formData.institutePhone}
                onChange={handleChange}
                placeholder="Institute Phone"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                id="instituteEmail"
                name="instituteEmail"
                value={formData.instituteEmail}
                onChange={handleChange}
                placeholder="Institute Email"
              />
            </div>

            <div className="formgroup">
              <i className="fas fa-map-marker-alt"></i>
              <input
                type="text"
                id="instituteLocation"
                name="instituteLocation"
                value={formData.instituteLocation}
                onChange={handleChange}
                placeholder="Institute Location"
              />
            </div>

            <div className="formgroup">
              <button type="submit" className="study-abroad-save">
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};

export default SACreate;
