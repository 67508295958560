import React, { useState, useEffect } from 'react';
import AdminDashboard from './AdminDashboard';
import '../../assets/css/admin/allcourses.css';

const DeleteEvents = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetchDeletedEvents();
    }, []);

    const fetchDeletedEvents = async () => {
        try {
            const response = await fetch('http://localhost:8081/events/deleted');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setEvents(data);
        } catch (error) {
            console.error('Error fetching deleted events:', error);
        }
    };

    const handleRestore = async (eventId) => {
        try {
            const response = await fetch(`http://localhost:8081/events/restore/${eventId}`, {
                method: 'POST'
            });
            if (response.ok) {
                setEvents(events.filter(event => event.id !== eventId));
                window.alert('Event Restored');
            } else {
                console.error('Failed to restore event');
            }
        } catch (error) {
            console.error('Error restoring event:', error);
        }
    };

    const handlePermanentDelete = async (eventId) => {
        try {
            const response = await fetch(`http://localhost:8081/events/delete/permanent/${eventId}`, {
                method: 'DELETE'
            });
            if (response.ok) {
                setEvents(events.filter(event => event.id !== eventId));
                window.alert('Event deleted permanently');
            } else {
                console.error('Failed to delete event permanently');
            }
        } catch (error) {
            console.error('Error deleting event permanently:', error);
        }
    };

    return (
        <div>
            <AdminDashboard />
            <div className='AllViews-admin-course'>
                <div className="search-icon-admin admin-course-search">
                    <h1>DELETE EVENTS</h1>
                    <form>
                        <input type="text" placeholder='Search Item' />
                        <input type="submit" value="SEARCH" />
                    </form>
                </div>
                <div className="scroller-box">
                    <div className="table-course">
                        <table>
                            <thead>
                                <tr>
                                    <th>Event Name</th>
                                    <th>University Name</th>
                                    <th>Venue</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>View</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {events.map((eventItem) => (
                                    <tr key={eventItem.id}>
                                        <td>{eventItem.eventName}</td>
                                        <td>{eventItem.university}</td>
                                        <td>{eventItem.venue}</td>
                                        <td>{new Date(eventItem.date).toLocaleDateString('en-CA')}</td>
                                        <td>{eventItem.time}</td>
                                        <td><a href={eventItem.link} target="_blank" rel="noopener noreferrer" id='view-website'>Link</a></td>
                                        <td id='action-buttons'>
                                            <a href="#!" id='restore-details' onClick={() => handleRestore(eventItem.id)}>RESTORE</a>
                                            <a href="#!" id='del-details' onClick={() => handlePermanentDelete(eventItem.id)}>DELETE</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteEvents;
