import React, { useState, useEffect } from 'react';
import '../assets/css/eventsecond.css';
import SecondNav from '../components/SecondNav';
import Footer from '../components/Footer';
import axios from 'axios';

const Event = () => {
    const [search, setSearch] = useState(''); 
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);

    const BaseUrl = 'http://localhost:8081/events/';

    useEffect(() => {
        axios.get(`${BaseUrl}all`)
            .then(response => {
                setEvents(response.data);
                setFilteredEvents(response.data);
            })
            .catch(err => console.error(err));
    }, []);

    // Filter events based on the search query
    useEffect(() => {
        if (search.trim() === '') {
            setFilteredEvents(events);
        } else {
            setFilteredEvents(events.filter(event =>
                event.eventName.toLowerCase().includes(search.toLowerCase())
            ));
        }
    }, [search, events]);

    // Function to handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div className='EventSecond'>
            <SecondNav />

            <div className="boxevent">
                <div className="containerBoxOne-event">
                    <div className="content-warp">
                        <h4>FIND YOUR EVENT</h4>
                        <p>Search Your Event</p>

                        <form id='formInstitutes' onSubmit={handleSubmit}>
                            <input 
                                type="text" 
                                placeholder="Enter Event" 
                                onChange={(e) => setSearch(e.target.value)} 
                            />
                            <input type="submit" value="Search" />
                        </form>
                    </div>
                </div>

                <div className="containerBoxtwoevent">
                    <div className="containerBoxevent">
                        {filteredEvents.length > 0 ? (
                            filteredEvents.map(event => (
                                <a key={event.id} href={`/eventview/${event.id}`}>
                                    <div className="ins-box-event">
                                        <div className="ins-box-img">
                                            <img 
                                                src={`http://localhost:8081/Upload/images/events/${event.banner}`} 
                                                alt="Event" 
                                            />
                                        </div>
                                        <div className="evnt-box-content">
                                            <h4>{event.eventName}</h4>
                                            <p>Venue: {event.venue}</p>
                                            <p>Date: {new Date(event.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                                            <p>Time: {event.time}</p>
                                        </div>
                                        <div className="fotter-box-event">
                                            <p>By: {event.university}</p>
                                        </div>
                                    </div>
                                </a>
                            ))
                        ) : (
                            <p style={{ color: "black" }}>No events found</p>
                        )}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Event;
